/**
 * Copyright ©2023 Drivepoint
 */

import {closeSnackbar, enqueueSnackbar} from "notistack";
import React, {createRef, forwardRef, useImperativeHandle} from "react";
import useStateChange from "@hooks/useStateChange";
import WebAppServerClient from "@services/clients/WebAppServerClient";
import RouteService from "@services/route/RouteService";
import ServiceRegistry from "@services/ServiceRegistry";
import State from "@services/state/State";
import Toast from "@services/Toast";
import DrivepointUser from "@services/user/DrivepointUser";
import DPMenu, {StandardMenuInterface, StandardMenuItem} from "../DPMenu/DPMenu";

export type NavigationPageItemMenuProps = {
  inNav?: boolean;
  onPageEdit?: (page: any) => void;
  ref?: any;
};

export type NavigationPageItemMenuInterface = {
} & StandardMenuInterface;

const NavigationPageItemMenu = forwardRef<NavigationPageItemMenuInterface, NavigationPageItemMenuProps>((props: NavigationPageItemMenuProps, ref: any): any => {

  useImperativeHandle(ref, (): NavigationPageItemMenuInterface => ({
    open: (element: HTMLElement, data?: any): void => {
      if (props.inNav && !data?.favorite) { return; }
      menu.current?.open(element, data);
    },
    close: () => close()
  }));

  const menu = createRef<StandardMenuInterface>();

  const actionsMenuItems: StandardMenuItem[] = [
    {id: "favorite", label: "${data?.favorite ? 'Remove Favorite' : 'Add Favorite'}"},
    {id: "edit", label: "Edit", visible: "${!!data?.custom}"}
  ];

  const company = useStateChange<any>("company");
  const user = useStateChange<DrivepointUser>("user");

  async function toggleFavorite(data: any): Promise<void> {
    const msg = data?.favorite ? `Removing ${data.name} from Favorites...` : `Adding ${data.name} to Favorites...`;
    const toastKey = Toast.progress(msg);
    let payload = {};
    if (data?.report_type === "dazzler") {
      payload = {type: "dazzler", id: data.id, name: data.name, company: company.id};
    }
    if (!user || !company) { return; }
    await WebAppServerClient.request(`/api/company/${company.id}/user/${user.user.email}/favorite/${data.id}/toggle`, "PATCH", payload);
    const sections = await ServiceRegistry.routeService.rehydrate();
    State.set("routes", {...State.get("routes"), sections});
    closeSnackbar(toastKey);
  }

  async function edit(page: any): Promise<void> {
    if (props.onPageEdit) { props.onPageEdit(page); }
  }

  async function onActionsMenuItemClick(item: StandardMenuItem, data?: any): Promise<void> {
    if (!data) { return; }
    switch (item.id) {
      case "favorite":
        toggleFavorite(data);
        break;
      case "edit":
        edit(data);
        break;
    }
  }

  return <DPMenu items={actionsMenuItems} onClick={onActionsMenuItemClick} ref={menu} />;

});

export default NavigationPageItemMenu;
