/**
 * Copyright ©2023 Drivepoint
 */

import dayjs from "dayjs";
import Gradient from "javascript-color-gradient";
import WebAppServerClient from "@services/clients/WebAppServerClient";
import type {CellFormatterForGradient} from "@utilities/template/libraries/types";

export default class TemplateJSLibraryFunctions {

  private GLOBALDATA: any;

  constructor(global_data: any) {
    this.GLOBALDATA = global_data;
  }

  // /// ENTRYPOINT FUNCTIONS
  prepFinancialMetricsWithVarianceReportFilterEntrypoint(metric_filter_key: any, addPath: boolean = false, includeIsActualToDataSet: boolean = false) {
    let variance_report_filter = true; // custom for this job
    this.prepFinancialMetrics(variance_report_filter, metric_filter_key, addPath, includeIsActualToDataSet); // pass what you need to other functions, this.GLOBALDATA .$ now contains data, formatter, etc..

    return this.GLOBALDATA; // hand it back if needed (helpful for testing)..
  }
  prepFinancialMetricsWithoutVarianceReportFilterEntrypoint(metric_filter_key: any, addPath: boolean = false, includeIsActualToDataSet: boolean = false) {
    let variance_report_filter = false;
    this.prepFinancialMetrics(variance_report_filter, metric_filter_key, addPath, includeIsActualToDataSet);
    return this.GLOBALDATA; // hand it back if needed (helpful for testing)..
  }

  renameSecondColumnToSomethingElseEntrypoint(new_name: any) {
    this.GLOBALDATA.$.data = this.renameSecondColumnToSomethingElse(this.GLOBALDATA.$.data, new_name);
    return this.GLOBALDATA;
  }
  addActualsToGlobalsEntrypoint() {
    this.GLOBALDATA.$.data = this.addActualsToGlobals(this.GLOBALDATA.$.data);
    return this.GLOBALDATA;
  }

  formatterForSalesReportTableEntrypoint() {
    this.GLOBALDATA.$.formatter = {...this.GLOBALDATA.$.formatter, ...this.formatterForSalesReportTable()};
    return this.GLOBALDATA;
  }

  formatterForSalesReportChartEntrypoint() {
    this.GLOBALDATA.$.formatter = {...this.GLOBALDATA.$.formatter, ...this.formatterForSalesReportChart()};
    return this.GLOBALDATA;
  }

  formatterForCohortTableEntrypoint() {
    this.GLOBALDATA.$.formatter = {...this.GLOBALDATA.$.formatter, ...this.formatterForCohortTable()};
    return this.GLOBALDATA;
  }

  buildMetricAsClauseEntrypoint(metrics_array: any) {
    return this.buildMetricAsClause(metrics_array);
  }

  formatPivotedCohortDataEntrypoint(pivot_key: any) {
    this.GLOBALDATA.$.data = this.formatPivotedCohortData(this.GLOBALDATA.$.data, pivot_key);
    return this.GLOBALDATA;
  }

  buildMetricCommentaryForMarkdownEntrypoint(date_column_name: any) {
    let alertsObject = this.buildMetricCommentary(this.GLOBALDATA.$.data, date_column_name);
    this.GLOBALDATA.$.alertsText = this.formatMetricCommentary(alertsObject);
    return this.GLOBALDATA;
  }

  buildMetricCommentaryForHtmlCellEntrypoint(date_column_name: any) {
    let alertsObject = this.buildMetricCommentaryHTML(this.GLOBALDATA.$.data, date_column_name);
    this.GLOBALDATA.$.alertsHTML = this.formatMetricCommentaryHTML(alertsObject);
    return this.GLOBALDATA;
  }

  pivotForGraphingFinancialDataEntrypoint() {
    this.GLOBALDATA.$.data = this.pivotForGraphingFinancialData(this.GLOBALDATA.$.data);
    return this.GLOBALDATA;
  }

  pivotFromChartFormatToTableEntrypoint(pivot_key: any, new_first_column_key: any) {
    this.GLOBALDATA.$.data = this.pivotFromChartFormatToTable(this.GLOBALDATA.$.data, pivot_key, new_first_column_key);
    return this.GLOBALDATA;
  }

  pivotFromTableFormatToChartEntrypoint(pivot_key: any, new_first_column_key: any) {
    this.GLOBALDATA.$.data = this.pivotFromTableFormatToChart(this.GLOBALDATA.$.data, pivot_key, new_first_column_key);
    return this.GLOBALDATA;
  }

  generateConfigMetadataForFinancialDataEntrypoint(metadata_from_sql: any, metric_type: any, metric_filter_key: any) {
    this.GLOBALDATA.$.formatter = this.generateConfigMetadataForFinancialData(this.GLOBALDATA.$.data, metadata_from_sql, metric_type, metric_filter_key);
    return this.GLOBALDATA;
  }

  formatRedGreenForPositiveNegativeValuesEntrypoint(metric_to_gradient: any, first_row_key: any, type: any, notation: any, decimals: number, grouping: any) {
    this.formatRedGreenForPositiveNegativeValues(metric_to_gradient, first_row_key, type, notation, decimals, grouping);
    return this.GLOBALDATA;
  }

  async getPlansDataEntrypoint() {
    this.GLOBALDATA.$.plans = await WebAppServerClient.request("/ui/company/madrabbit/plans", "GET"); // TODO: get company_id
  }

  async getBainbridgeUserEntrypoint() {
    this.GLOBALDATA.$.user = await WebAppServerClient.request("/ui/bainbridge_user", "GET"); // TODO: get company_id
  }

  formatColumnsAsModelDatePatternEntrypoint(element_name: any, pattern = "MMM-YY") {
    this.formatColumnsAsModelDatePattern(element_name, pattern);
    return this.GLOBALDATA;
  }

  formatRowsAsModelDatePatternEntrypoint(element_name: any,  pattern = "MMM-YY") {
    this.formatRowsAsModelDatePattern(element_name, pattern);
    return this.GLOBALDATA;
  }

  formatCellsAsModelDatePatternEntrypoint(element_name: any,  pattern = "MMM-YY") {
    this.formatCellsAsModelDatePattern(element_name, pattern);
    return this.GLOBALDATA;
  }

  formatElementsWithCustomFormatEntrypoint(element_name: any, custom_property: any, element_type: any, value: any) {
    this.formatElementsWithCustomFormat(element_name, custom_property, element_type, value);
    return this.GLOBALDATA;
  }

  formatAxisMinAndMaxByMultiplierEntrypoint(axisSide: any, minMultiplier: any, maxMultiplier: any, metric_key: any, min_override: any, max_override: any) {
    this.formatAxisMinAndMaxByMultiplier(axisSide, minMultiplier, maxMultiplier, metric_key, min_override, max_override);
    return this.GLOBALDATA;
  }

  addBlankRowsToFinancialMetricsEntrypoint() {
    this.GLOBALDATA.$.data = this.addBlankRowsToFinancialMetrics(this.GLOBALDATA.$.data);
    return this.GLOBALDATA;
  }

  attachAdditionalPlanDataToPrimaryPlanEntrypoint(metric_key_name: any, data_key_name: any, additional_plan_data_key: any, additional_plan_name: any, metric_filter_key: any) {
    this.GLOBALDATA.$.data = this.attachAdditionalPlanDataToPrimaryPlan(metric_key_name, data_key_name, additional_plan_data_key, additional_plan_name, metric_filter_key);
    return this.GLOBALDATA;
  }

  setUpDefaultFormatStructureForSingleTypeEntrypoint(format_name: any) {
    this.set_up_default_format_structure(format_name);
    return this.GLOBALDATA;
  }

  setUpDefaultFormatStructureForAllTypesEntrypoint() {
    this.set_up_default_format_structure_for_all_types();
    return this.GLOBALDATA;
  }

  // ///// END Entrypoint functions
  // ///////////// BEGIN FORMATTER FUNCTIONS

  formatterForCohortTable() {
    // here we have already done row level formatting
    // since rows take a higher priority than columns the only way to get a single colum
    let formatter_object: any = {};
    formatter_object = {columns: {formatters: [], formatter: {}}};

    formatter_object.columns.formatter =       {
      type: "percent",
      decimals: 2
    };
    formatter_object.columns?.formatters.push(
      {
        column: "0",
        formatter: {
          datePattern: "MMM YY",
          type: "date",
          width: 150,
          style: {"fontWeight": 600}
        }
      }
    );
    return formatter_object;
  }

  formatterForSalesReportChart() {
    let formatter_object: any = {};
    formatter_object = {columns: {formatters: [], formatter: {}}};
    formatter_object.columns.formatter =
      {
        type: "currency",
        decimals: 2
      };

    formatter_object.columns.formatters.push(
      {
        column: "DAY",
        formatter: {
          type: "date"
        }
      },
      {
        column: "WEEK",
        formatter: {
          type: "week"
        }
      },
      {
        column: "MONTH",
        formatter: {
          type: "date",
          period: "month"
        }
      },
      {
        column: "QUARTER",
        formatter: {
          type: "date",
          period: "quarter"
        }
      },
      {
        column: "YEAR",
        formatter: {
          type: "date",
          datePattern: "YYYY"
        }
      },
      {
        column: "Orders",
        formatter: {
          type: "number",
          decimals: 0
        }
      }
    );
    return formatter_object;

  }
  formatterForSalesReportTable() {
    let configMetadataReturn: any = {};

    let default_column_formatter_object = {
      width: 150,
      type: "currency",
      currency: "USD",
      decimals: 2
    };

    let default_row_formatter_object = {
      width: 100
    };
    let default_cell_formatter_object = {
      width: 100
    };

    let column_formatter_object_product_title = {
      column: "Product Title",
      formatter: {
        type: "string"
      }
    };
    let column_formatter_object_product_category = {
      column: "Product Category",
      formatter: {
        type: "string"
      }
    };
    let column_formatter_object_order_date = {
      column: "order_date",
      formatter: {
        width: 200,
        type: "date"
      }
    };
    let column_formatter_object_date = {
      column: "Date",
      formatter: {
        width: 200,
        type: "date"
      }
    };
    let column_formatter_object_day = {
      column: "DAY",
      formatter: {
        width: 200,
        type: "date",
        period: "day"
      }
    };
    let column_formatter_object_week = {
      column: "WEEK",
      formatter: {
        width: 200,
        type: "date",
        period: "week"
      }
    };
    let column_formatter_object_month = {
      column: "MONTH",
      formatter: {
        width: 200,
        type: "date",
        period: "month"
      }
    };
    let column_formatter_object_quarter = {
      column: "QUARTER",
      formatter: {
        width: 200,
        type: "date",
        period: "quarter"
      }
    };
    let column_formatter_object_year = {
      column: "YEAR",
      formatter: {
        width: 200,
        type: "date"
      }
    };

    let column_formatter_object_orders = {
      column: "Orders",
      formatter: {
        type: "number",
        decimals: 0
      }
    };
    let column_formatters_array = [
      column_formatter_object_product_title,
      column_formatter_object_product_category,
      column_formatter_object_date,
      column_formatter_object_order_date,
      column_formatter_object_day,
      column_formatter_object_week,
      column_formatter_object_month,
      column_formatter_object_quarter,
      column_formatter_object_year,
      column_formatter_object_orders
    ];

    let row_formatters_array = [];
    let cell_formatters_array = [];

    // column_formatters_array.push(default_column_formatter_object)
    // row_formatters_array.push(default_row_formatter_object)
    // cell_formatters_array.push(default_cell_formatter_object)

    configMetadataReturn["columns"] = {
      formatter: default_column_formatter_object,
      formatters: column_formatters_array
    };
    //   configMetadataReturn['rows'] = {
    //     formatter: default_row_formatter_object,
    //     formatters: row_formatters_array
    //   }
    //   configMetadataReturn['cells'] = {
    //     formatter: default_cell_formatter_object,
    //     formatters: cell_formatters_array
    //   }
    return configMetadataReturn;
  }

  /*
    will append formatting to any elements that already exist.. not create them
    * element_name = the acutal name of the cell/column/row you want to change here
    * custom_property = the property to override
    * type = row, column or cell
    * value = the value you want to use

    This replicates  formatColumnsAsModelDatePattern("Date", "MMM YY")
    $LIB.JS.formatElementsWithCustomFormat("Date", "datePattern" ,"column", "MMM YY DD");
    $LIB.JS.formatElementsWithCustomFormat("Date", "type" ,"column", "date");

   */
  formatElementsWithCustomFormat(element_name: any, custom_property: any, type: any, value: any) {
    if (!(value && element_name && custom_property && type)) {
      logger.warn(`YOU MUST SUPPLY A SET OF PROPERTIES TO formatCustomColumnWithCustomFormat.. you sent pattern=${value} element_name=${element_name} custom_property=${custom_property} type=${type}, returning without performing any changes.`);
      return this.GLOBALDATA;
    }
    if (!["cell", "row", "column"].includes(type)) {
      logger.warn(` YOU MUST PASS a valid type of ["cell", "row", "column"], you passed ${type}, returning without performing any changes.`);
      return this.GLOBALDATA;
    }
    let type_plural = `${type}s`;
    let found_entry = false;
    this.set_up_default_format_structure(type);

    for (let format of this.GLOBALDATA.$.formatter?.[type_plural]?.formatters) {
      // if (date_primatives?.includes(format?.column?.toLowerCase())){
      if (element_name.toString().toLowerCase() === format?.[type]?.toLowerCase()) {
        format.formatter[custom_property] = value;
        found_entry = true;
      }
    }
    if (!found_entry) {
      this.GLOBALDATA.$.formatter[type_plural]?.formatters.push(
        {
          [type]: element_name,
          formatter: {
            [custom_property]: value
          }
        }
      );
    }

    return this.GLOBALDATA;
  }

  /*
    Safely append formatting information for a column
      if we don't have the appropriate formatters object it will be built
   */
  formatElementsAsModelDatePattern(type: any, element_name: any, pattern = "MMM-YY") {
    if (!["cell", "row", "column"].includes(type)) {
      throw new Error(` YOU MUST PASS a valid type of ["cell", "row", "column"], you passed ${type}, returning without performing any changes.`);
    }
    let type_plural = `${type}s`;
    let found_date_entry = false;
    this.set_up_default_format_structure(type);

    for (let format of this.GLOBALDATA.$.formatter?.[type_plural]?.formatters) {
      // if (date_primatives?.includes(format?.column?.toLowerCase())){
      if (element_name.toString().toLowerCase() === format?.type?.toLowerCase()) {
        format.formatter.datePattern = pattern;
        format.formatter.type = "date";
        found_date_entry = true;
      }
    }
    // TOOD: if 'cell' then we need to do more work here.
    if (!found_date_entry) {
      this.GLOBALDATA.$.formatter[type_plural]?.formatters.push(
        {
          [type]: element_name,
          formatter: {
            datePattern: pattern,
            type: "date"
          }
        }
      );
    }
    return this.GLOBALDATA;
  }

  private set_up_default_format_structure_for_all_types() {
    this.set_up_default_format_structure("column");
    this.set_up_default_format_structure("row");
    this.set_up_default_format_structure("cell");
    this.set_up_default_format_structure("headers");
    this.set_up_default_format_structure("dataPoints");
    this.set_up_default_format_structure("annotations");
    this.set_up_default_format_structure("vAxes");
  }

  private set_up_default_format_structure(type: any) {
    if (!this.GLOBALDATA.$?.formatter) {
      this.GLOBALDATA.$.formatter = {};
    }
    if (type == "column") {
      if (!this.GLOBALDATA.$?.formatter?.columns) {
        this.GLOBALDATA.$.formatter.columns = {};
      }
      if (!this.GLOBALDATA.$?.formatter?.columns?.formatters) {
        this.GLOBALDATA.$.formatter.columns.formatters = [];
      }
      if (!this.GLOBALDATA.$?.formatter?.columns?.formatter) {
        this.GLOBALDATA.$.formatter.columns.formatter = {};
      }
    }
    if (type == "row") {
      if (!this.GLOBALDATA.$?.formatter?.rows) {
        this.GLOBALDATA.$.formatter.rows = {};
      }
      if (!this.GLOBALDATA.$?.formatter?.rows?.formatters) {
        this.GLOBALDATA.$.formatter.rows.formatters = [];
      }
      if (!this.GLOBALDATA.$?.formatter?.rows?.formatter) {
        this.GLOBALDATA.$.formatter.rows.formatter = {};
      }
    }
    if (type == "cell") {
      if (!this.GLOBALDATA.$?.formatter?.cells) {
        this.GLOBALDATA.$.formatter.cells = {};
      }
      if (!this.GLOBALDATA.$?.formatter?.cells?.formatters) {
        this.GLOBALDATA.$.formatter.cells.formatters = [];
      }
      if (!this.GLOBALDATA.$?.formatter?.cells?.formatter) {
        this.GLOBALDATA.$.formatter.cells.formatter = {};
      }
    }
    if (type == "headers") {
      if (!this.GLOBALDATA.$?.formatter?.headers) {
        this.GLOBALDATA.$.formatter.headers = {};
      }
      if (!this.GLOBALDATA.$?.formatter?.headers?.formatters) {
        this.GLOBALDATA.$.formatter.headers.formatters = [];
      }
      if (!this.GLOBALDATA.$?.formatter?.headers?.formatter) {
        this.GLOBALDATA.$.formatter.headers.formatter = {};
      }
    }
    if (type == "dataPoints") {
      if (!this.GLOBALDATA.$?.formatter?.dataPoints) {
        this.GLOBALDATA.$.formatter.dataPoints = {};
      }
      if (!this.GLOBALDATA.$?.formatter?.dataPoints?.formatters) {
        this.GLOBALDATA.$.formatter.dataPoints.formatters = [];
      }
      if (!this.GLOBALDATA.$?.formatter?.dataPoints?.formatter) {
        this.GLOBALDATA.$.formatter.dataPoints.formatter = {};
      }
    }
    if (type == "annotations") {
      if (!this.GLOBALDATA.$?.formatter?.annotations) {
        this.GLOBALDATA.$.formatter.annotations = {};
      }
      if (!this.GLOBALDATA.$?.formatter?.annotations?.formatters) {
        this.GLOBALDATA.$.formatter.annotations.formatters = [];
      }
      if (!this.GLOBALDATA.$?.formatter?.annotations?.formatter) {
        this.GLOBALDATA.$.formatter.annotations.formatter = {};
      }
    }
    if (type == "vAxes") {
      if (!this.GLOBALDATA.$?.formatter?.vAxes) {
        this.GLOBALDATA.$.formatter.vAxes = {};
      }
      if (!this.GLOBALDATA.$?.formatter?.vAxes?.formatters) {
        this.GLOBALDATA.$.formatter.vAxes.formatters = [];
      }
      if (!this.GLOBALDATA.$?.formatter?.vAxes?.formatter) {
        this.GLOBALDATA.$.formatter.vAxes.formatter = {};
      }
    }
  }

  formatColumnsAsModelDatePattern(element_name: any, pattern = "MMM-YY") {
    return this.formatElementsAsModelDatePattern("column", element_name, pattern);
  }
  formatRowsAsModelDatePattern(element_name: any, pattern = "MMM-YY") {
    return this.formatElementsAsModelDatePattern("row", element_name, pattern);
  }
  formatCellsAsModelDatePattern(element_name: any, pattern = "MMM-YY") {
    return this.formatElementsAsModelDatePattern("cell", element_name, pattern);
  }

  // formatRowsAsModelDatePattern(pattern="MMM-YY", custom_name=""){
  //   let date_primatives = ["date", "time","month", "day", "week","month","quarter", "year"]
  //   if (custom_name){
  //     date_primatives.push(custom_name.toString().toLowerCase())
  //   }
  //   for (let format of this.GLOBALDATA.$.formatter.rows?.formatters){
  //     if (date_primatives?.includes(format?.row?.toLowerCase())){
  //       format.formatter.datePattern = pattern
  //       format.formatter.type = "date"
  //     }
  //   }
  //   return this.GLOBALDATA;
  // }

  // ///////////// END FORMATTER FUNCTIONS
  // /////////////// BELOW ARE FOR Variance Report, PandL report and those reports that need to take data from Excel
  renameSecondColumnToSomethingElse(pivotedActuals: any, new_name: any) {
    return this.renamenthColumnToSomethingElse(pivotedActuals, new_name, 1);
  }

  renameDateColumnToSomethingElse(pivotedActuals: any, new_name: any) {
    if (pivotedActuals && pivotedActuals.length > 0) {
      let counter = 0;
      for (let row of pivotedActuals) {
        for (let d in row) {
          if (dayjs(d, "YYYY-MM-DD", true).isValid()) {
            // if (d=='2023-12-01') {
            row[new_name] = row[d];
            delete row[d];
          }
        }
      }
    }
    return pivotedActuals;
  }

  // this is zero based so 1 means 2nd element etc..
  renamenthColumnToSomethingElse(pivotedActuals: any, new_name: any, key_index: any) {
    let newdata = [];
    if (pivotedActuals && pivotedActuals.length) {
      for (let d of pivotedActuals) {
        let keys = Object.keys(d);
        // yeah crappy.. we know one is Metric, then change the date to 'actual
        if (keys[0] == "Metric") {
          newdata.push({
            "Metric": d?.Metric,
            [new_name]: d[keys[key_index]]
          });
        } else {
          newdata.push({
            "Metric": d?.Metric,
            [new_name]: d[keys[key_index]]
          });
        }
      }
      pivotedActuals = newdata;
    }
    // kinda crappy way to double ensure we have the header as "Actual"
    return pivotedActuals;
  }

  hydrateDataWithIndentLvl(data: Record<string, any>[], formatters: Record<string, any>[]) {
    data.forEach(((el, index) => {
      const indentLevel = formatters.find(formatter => el[formatter?.rowName] === formatter?.rowValue)?.formatter?.indent ?? 0;
      data[index] = {...el, indentLevel};
    }));

    return data;
  }

  handlePathForTree(data: Record<string, any>[]) {
    let pathStart: number = 0;
    let lvlCounter: number = 0;
    let path: number[] = [];
    let prevIndent: number = 0;
    data.forEach((el, index) => {
      if (el.indentLevel === 0) {
        pathStart += 1;
        lvlCounter = 0;
        path = [pathStart];
      }

      if (el.indentLevel > 0) {
        if (prevIndent === el.indentLevel) {
          path[path.length - 1] = lvlCounter + 1;
          lvlCounter += 1;
        }
        if (prevIndent > el.indentLevel) {
          if (path[prevIndent]) { path.splice(prevIndent); }
          lvlCounter = path[path.length - 1];
          path[path.length - 1] = (lvlCounter + 1);
          lvlCounter += 1;
        }
        if (prevIndent < el.indentLevel) {
          lvlCounter = 0;
          path.push(lvlCounter + 1);
          lvlCounter += 1;
        }
      }

      prevIndent = el.indentLevel;
      data[index] = {path: [...path], ...el};
      delete data[index].indentLevel;

    });

    return data;

  }

  handleIsActual(data: Record<string, any>[]) {
    const isActual =  this.GLOBALDATA.$.m_monthly_lastest_closed_financials?.find(
      (el: Record<string, any>) => el.date_bom === this.GLOBALDATA.$CONTEXT?.month
    )?.actual ?? false;
    return data.map(el => ({...el, isActual}));
  }

  prepFinancialMetrics(variance_report_filter: boolean, metric_filter_key: any, addPath: boolean = false, includeIsActualToDataSet: boolean = false) {
    // variance_report_filter = false;

    if (variance_report_filter) {
      // DIFF FROM MAINLINE!! here we run a filter to get rid of everything but the one month we want!
      this.set_up_default_format_structure_for_all_types();

      if (!this.GLOBALDATA?.$?.m_monthly_lastest_closed_financials) {
        throw new Error("$.m_monthly_lastest_closed_financials is a required input");
      }
      if (!this.GLOBALDATA?.$?.metadata_from_sql) {
        throw new Error("$.metadata_from_sql is a required input");
      }

      // produce now actuals, last month actuals, and same month last year actuals
      let m_monthly_lastest_closed_financials_out = this.GLOBALDATA?.$.m_monthly_lastest_closed_financials.filter((row: any) => {
        return row?.date_bom == this.GLOBALDATA?.$CONTEXT?.month;
      });
      const last_month = dayjs(this.GLOBALDATA?.$CONTEXT?.month).subtract(1, "month").format("YYYY-MM-DD");
      const last_year = dayjs(this.GLOBALDATA?.$CONTEXT?.month).subtract(1, "year").format("YYYY-MM-DD");

      let m_monthly_lastest_closed_financials_last_month_out = this.GLOBALDATA?.$.m_monthly_lastest_closed_financials.filter((row: any) => {
        return row?.date_bom == last_month;
      });

      let m_monthly_lastest_closed_financials_last_year_out = this.GLOBALDATA?.$.m_monthly_lastest_closed_financials.filter((row: any) => {
        return row?.date_bom == last_year;
      });

      // HERE IS THE USER PIECE!! -- BEGIN

      let period = this.getPeriodFromContext(this.GLOBALDATA?.$CONTEXT);
      let metric_type = this.getMetricTypeFromContext(this.GLOBALDATA?.$CONTEXT);
      let metadata_from_sql = this.GLOBALDATA?.$.metadata_from_sql;
      // DIFF FROM MAINLINE!! here we run a filter to get rid of everything but the one month we want!
      let filtered_data = m_monthly_lastest_closed_financials_out;
      if (period !== "MONTH") {
        filtered_data = this.insertCalculatedMetricsForPeriod(metric_type, period, "x", filtered_data, "");
      }

      let pivoted = this.applyFriendlyNamesAndFormatFinancialMetrics(filtered_data, metadata_from_sql, metric_type, metric_filter_key);
      let pivoted_last_month = this.applyFriendlyNamesAndFormatFinancialMetrics(m_monthly_lastest_closed_financials_last_month_out, metadata_from_sql, metric_type, metric_filter_key);
      let pivoted_last_year = this.applyFriendlyNamesAndFormatFinancialMetrics(m_monthly_lastest_closed_financials_last_year_out, metadata_from_sql, metric_type, metric_filter_key);

      pivoted = this.sortMetricsByOrderField(pivoted);
      pivoted = this.addBlankRowsToFinancialMetrics(pivoted);

      // TODO: we really should just align on the spine of pivoted here to be safer.., hard to see how we would get mistakes
      // since its from the same query but yeah..
      pivoted_last_month = this.sortMetricsByOrderField(pivoted_last_month);
      pivoted_last_month = this.addBlankRowsToFinancialMetrics(pivoted_last_month);

      pivoted_last_year = this.sortMetricsByOrderField(pivoted_last_year);
      pivoted_last_year = this.addBlankRowsToFinancialMetrics(pivoted_last_year);

      // DIFF FROM MAINLINE!! here we dont return anything, just set a global... and not a formatter
      pivoted = this.renameSecondColumnToSomethingElse(pivoted, "Actuals");
      pivoted_last_month = this.renameSecondColumnToSomethingElse(pivoted_last_month, "Month Over Month");
      pivoted_last_year = this.renameSecondColumnToSomethingElse(pivoted_last_year, "Year Over Year");
      this.GLOBALDATA.$.m_monthly_lastest_closed_financials_final = pivoted;
      this.GLOBALDATA.$.m_monthly_lastest_closed_financials_final_last_month = pivoted_last_month;
      this.GLOBALDATA.$.m_monthly_lastest_closed_financials_final_last_year = pivoted_last_year;
      this.GLOBALDATA.$.formatter = {...this.GLOBALDATA.$.formatter, ...this.generateConfigMetadataForFinancialData(filtered_data, metadata_from_sql, metric_type, metric_filter_key)};
      // these aren't additive, the row level formats are already set
      // this.GLOBALDATA.$.formatter = {...this.GLOBALDATA.$.formatter, ...this.generateConfigMetadataForFinancialData(pivoted_last_month, metadata_from_sql, metric_type, metric_filter_key)};
      // this.GLOBALDATA.$.formatter = {...this.GLOBALDATA.$.formatter, ...this.generateConfigMetadataForFinancialData(pivoted_last_year, metadata_from_sql, metric_type, metric_filter_key)};

      // console.log("Pivoted:::");
      // console.log(pivoted);
      console.log("$.formatter", this.GLOBALDATA.$.formatter);
      // return pivoted
    } else {
      // HERE IS THE USER PIECE!! -- BEGIN
      this.set_up_default_format_structure_for_all_types();

      let period = this.getPeriodFromContext(this.GLOBALDATA?.$CONTEXT);
      let metric_type = this.getMetricTypeFromContext(this.GLOBALDATA?.$CONTEXT);
      let metadata_from_sql = this.GLOBALDATA?.$?.metadata_from_sql;

      if (!this.GLOBALDATA?.$?.metadata_from_sql) {
        throw new Error("$.metadata_from_sql is a required input");
      }

      // let filtered_data = applyDateFilter(data, this.GLOBALDATA?.$CONTEXT?.date?.start, this.GLOBALDATA?.$CONTEXT?.date?.end)
      let filtered_data = this.GLOBALDATA.$.data;
      if (period !== "MONTH") {
        filtered_data = this.insertCalculatedMetricsForPeriod(metric_type, period, "x", filtered_data, "");
      }

      let pivoted: any = this.applyFriendlyNamesAndFormatFinancialMetrics(filtered_data, metadata_from_sql, metric_type, metric_filter_key);

      pivoted = this.sortMetricsByOrderField(pivoted);
      pivoted = this.addBlankRowsToFinancialMetrics(pivoted);

      // pivoted = addBenchmarksData(pivoted); // not yet implemented
      // pivoted = addMetricFormulaHovers(pivoted);
      // HERE IS THE USER PIECE!! - END
      // muiTable = formatParentChidMetricsForTable(pivoted, muiTable);
      // muiTable = addHoverInfoBubble(pivoted, muiTable);
      // muiTable = makeTableCollapsable(pivoted, muiTable);

      this.GLOBALDATA.$.formatter = {...this.GLOBALDATA.$.formatter, ...this.generateConfigMetadataForFinancialData(filtered_data, metadata_from_sql, metric_type, metric_filter_key)};
      // this.GLOBALDATA.$.formatter = this.generateConfigMetadataForFinancialData(filtered_data, metadata_from_sql, metric_type);

      // console.log("Pivoted:::");
      // console.log(pivoted);
      // console.log("$.formatter", this.GLOBALDATA.$.formatter);
      this.GLOBALDATA.$.data = pivoted;
      // return pivoted
    }

    if (addPath) {
      this.GLOBALDATA.$.data = this.hydrateDataWithIndentLvl(this.GLOBALDATA.$.data ?? [], this.GLOBALDATA.$.formatter?.cells?.formatters ?? []);
      this.GLOBALDATA.$.data = this.handlePathForTree(this.GLOBALDATA.$.data);
    }

    if (includeIsActualToDataSet) {
      this.GLOBALDATA.$.data = this.handleIsActual(this.GLOBALDATA.$.data);
    }

  }

  attachAdditionalPlanDataToPrimaryPlan(metric_key_name: any, data_key_name: any, additional_plan_data_key: any, additional_plan_name: any, metric_filter_key: any) {
    let period = this.getPeriodFromContext(this.GLOBALDATA?.$CONTEXT);
    let metric_type = this.getMetricTypeFromContext(this.GLOBALDATA?.$CONTEXT);
    let metadata_from_sql = this.GLOBALDATA?.$?.metadata_from_sql;

    if (!this.GLOBALDATA?.$?.metadata_from_sql) {
      throw new Error("$.metadata_from_sql is a required input");
    }

    if (!(this.GLOBALDATA?.$.data?.length && this.GLOBALDATA?.$.data?.length > 0)) {
      return this.GLOBALDATA.$.data;
    }
    let additional_plan_data = this.GLOBALDATA.$[additional_plan_data_key];

    if (!(additional_plan_data?.length && additional_plan_data?.length > 0)) {
      return this.GLOBALDATA.$.data;
    }

    let additional_plan_data_pivoted: any = this.applyFriendlyNamesAndFormatFinancialMetrics(additional_plan_data, metadata_from_sql, metric_type, metric_filter_key);
    additional_plan_data_pivoted = this.renameDateColumnToSomethingElse(additional_plan_data_pivoted, additional_plan_name);

    for (let row of this.GLOBALDATA.$.data) {
      let found_data = false;
      let metric_name = row[metric_key_name];
      for (let new_data_row of additional_plan_data_pivoted) {
        if (new_data_row[metric_key_name] == metric_name) {
          row[additional_plan_name] = new_data_row[additional_plan_name];
        }
      }
    }
    return this.GLOBALDATA.$.data;
  }

  generateConfigMetadataForFinancialData(data: any, metadata_from_sql: any, metric_type: any, metric_filter_key: any) {
    if (!(data?.length && data?.length > 0)) {
      return this.GLOBALDATA?.formatter; // don't corrupt good formatters if we get an empty data array..
    }
    let configMetadataReturn: any = {};
    let keys = this.generateMetricKeysFromRawData(data, metric_type, metric_filter_key);
    if (!(keys?.length && keys?.length > 0)) {
      return this.GLOBALDATA?.formatter;// don't corrupt good formatters if we dont match a key
    }
    let pivoted_obj = {};
    let config_obj;
    let default_column_formatter_object = {
      width: 100,
      align: "right"
    };
    let default_row_formatter_object = {
      width: 100
    };
    let default_cell_formatter_object = {
      width: 100
    };
    let row_formatters_array = [];
    let cell_formatters_array = [];
    let column_formatters_array = [];
    let row_formatters_map: any = {};
    let cell_formatters_map: any = {};
    let column_formatter_object: any = {};
    let cell_formatter_object: any = {};
    let row_formatter_object: any = {};

    for (let item of data) {
      for (let key of keys) {
        let row_formatter_object = {};
        let cell_formatter_object = {};

        let indentLevel = 0;
        let order = 1000;
        let pretty_key = this.getFriendlyNameFromMetadata(key, metadata_from_sql);
        if (!pretty_key) {
          continue;
        }
        let metadata = metadata_from_sql.find((it: any) => {
          return key.toLowerCase() == it.metric.toLowerCase();
        });
        let prettyKeyWithIndent = pretty_key;
        let metadata_details: any = {};
        let positive_excel_format = "";
        let negative_excel_format = "";
        let number_type = "";
        let data_type = "currency";
        let decimals = 0;
        let notation = "financial"; // ,"financial" // TODO: to make 'financial'
        let grouping = true;
        if (metadata?.metadata_json) {
          try {
            metadata_details = JSON.parse(metadata?.metadata_json);
          } catch (e) {
            console.log(e);
          }

          data_type = "currency";
          const __ret = this.extractIndentLevelAndOrder(order, metadata_details, indentLevel, positive_excel_format, negative_excel_format);
          indentLevel = __ret.indentLevel;
          positive_excel_format = __ret.positive_excel_format;
          negative_excel_format = __ret.negative_excel_format;
          order = __ret.order;

          if (metadata_details?.numberFormatCategory == "Percentage" || metadata_details?.numberFormat == "0%" || metadata_details?.worksheetStylesDataColumn?.pattern == "0%") {
            data_type = "percent";
            notation = "standard";
            grouping = false;
          } else if (metadata_details?.numberFormatCategory == "Number" ||
            metadata_details?.numberFormat == "0" ||
            positive_excel_format.trim() == "#,##0 " ||
            negative_excel_format.trim() == "(#,##0)") {
            data_type = "number";
            decimals = 0;
          }

        }

        column_formatter_object = {
          value: "Metric", // to be deprecated!!!
          column: "Metric",
          formatter: {
            align: "left",
            width: 300
          }
        };

        let fontWeightStyle: any = {};
        if (["Gross Sales", "Discounts", "Returns", "Shipping Income", "Taxes Collected",
          "Net Revenue", "Cost of Goods Sold", "Product Cost", "Import Freight Cost",
          "Import Duties & Taxes", "Gross Profit", "Fulfillment Cost", "Other Variable Cost",
          "Shipping Expense", "Merchant Fees", "Variable Expenses", "Contribution Profit",
          "Direct Advertising", "Other Advertising", "Other Marketing", "Marketing Agency",
          "Total Marketing Expenses", "Payroll", "Legal & Professional", "Contractors",
          "People Costs", "G&A", "R&D", "Depreciation", "Amortization", "Other Operating Expenses",
          "Operating Expenses", "Operating Income (EBIT)", "Other Expenses", "Other Income",
          "Interest Expense", "Pre-Tax Income", "Income Tax Expense", "Fulfillment Costs",
          "Other Variable Costs",
          "Net Income", "EBIT", "Depreciation", "Amortization", "EBITDA",
          "Total Assets", "Total Liabilities", "Total Equities"].includes(pretty_key)) {
          fontWeightStyle.fontWeight = 800;
        }

        // note the alignment doesnt work on a row
        row_formatter_object = {
          column: "Metric",
          row: pretty_key,
          formatter: {
            type: data_type,
            notation,
            grouping,
            decimals,
            style: fontWeightStyle
          }
        };
        cell_formatter_object = {
          column: "Metric",
          rowName: "Metric",
          rowValue: pretty_key,
          formatter: {
            indent: indentLevel,
            type: "string",
            width: 200,
            style: fontWeightStyle
          }
        };
        row_formatters_map[pretty_key] = row_formatter_object;
        cell_formatters_map[pretty_key] = cell_formatter_object;

      }
    }
    column_formatters_array.push(column_formatter_object);
    row_formatters_array = Object.values(row_formatters_map);
    cell_formatters_array = Object.values(cell_formatters_map);
    configMetadataReturn = {};
    configMetadataReturn["columns"] = {
      formatter: default_column_formatter_object,
      formatters: column_formatters_array
    };
    configMetadataReturn["rows"] = {
      formatter: default_row_formatter_object,
      formatters: row_formatters_array
    };
    configMetadataReturn["cells"] = {
      formatter: default_cell_formatter_object,
      formatters: cell_formatters_array
    };

    return configMetadataReturn;
  }

  private extractIndentLevelAndOrder(order: number, metadata_details: any, indentLevel: number, positive_excel_format: string, negative_excel_format: string) {
    order = metadata_details?.rowNumber || metadata_details?.worksheetStylesNameColumn?.rowNumber || order;
    indentLevel = metadata_details?.indentLevel || metadata_details?.worksheetStylesNameColumn?.alignment?.indent || indentLevel;
    if (metadata_details?.numberFormat && metadata_details?.numberFormat?.split(";").length > 1) {
      positive_excel_format = metadata_details?.numberFormat?.split(";")[0];
      negative_excel_format = metadata_details?.numberFormat?.split(";")[1];
    }
    if (metadata_details?.worksheetStyleDataColumn?.numberFormat && metadata_details?.worksheetStylesDataColumn?.pattern && metadata_details?.worksheetStylesDataColumn?.pattern?.split(";").length > 1) {
      positive_excel_format = metadata_details?.worksheetStylesDataColumn?.pattern?.split(";")[0];
      negative_excel_format = metadata_details?.worksheetStylesDataColumn?.pattern?.split(";")[1];
    }
    return {indentLevel, positive_excel_format, negative_excel_format, order};
  }

  // TODO: how to do this safely
  // need to prime these from the query from the right date range..
  // add calculated Metrics
  // given the time window figure out 'latest' and 'previous' dates..
  // do any massaging of time windows here
  // then add the new calculated metric
  // second arg is calculation_or_formula "calculation" or "formulas"
  buildCalculatedMetrics(item: any, calculation_or_formula: any) {
    let quickbooks_total_income_latest = item["monthly___nosegment___incomestatement_netrevenue"];
    let quickbooks_total_cogs_latest = item["monthly___nosegment___incomestatement_costofgoodssold"];
    let quickbooks_variable_expenses_latest = item["monthly___nosegment___incomestatement_variableexpenses"];
    let quickbooks_total_income_previous = 4;
    let quickbooks_total_cogs_previous = 5;
    let quickbooks_variable_expenses_previous = 6;

    const metrics_library = {
      "incomestatement_contributionprofit": {
        id: "incomestatement_contributionprofit",
        name: "Contribution Profit",
        section: "Margins",
        observation_period: "LTM",
        metric_type: "value",
        display: "percent",
        description: "The Amount of revenue you keep after paying for the products and all costs associated with getting them to your customers' doorstep. Used to understand how much you have left over for overhead, salaries, etc.",
        benchmarks_data_source: "external_source",
        calculation_latest: `${(quickbooks_total_income_latest)} - ${(quickbooks_total_cogs_latest)} - ${(quickbooks_variable_expenses_latest)}  `,
        calculation_previous: `((${(quickbooks_total_income_previous)} - ${(quickbooks_total_cogs_previous)} - ${(quickbooks_variable_expenses_previous)})  )`,
        formula_latest: `(${quickbooks_total_income_latest} - ${quickbooks_total_cogs_latest} - ${quickbooks_variable_expenses_latest}) `,
        formula_previous: `(${quickbooks_total_income_previous} - ${quickbooks_total_cogs_previous} - ${quickbooks_variable_expenses_previous})  `,
        definition_latest: "(quickbooks_total_income_latest - quickbooks_total_cogs_latest - quickbooks_variable_expenses_latest) ",
        definition_previous: "(quickbooks_total_income_previous - quickbooks_total_cogs_previous - quickbooks_variable_expenses_previous)  "
      }
    };
    let calculated_obj: any = {};
    let return_obj = {};
    if (calculation_or_formula === "calculation") {
      for (let calculated_metric_recipie of Object.values(metrics_library)) {
        // calculated_obj[`${calculated_metric_recipie.name}_calculation_latest`] = {
        //     'Metric': `${calculated_metric_recipie.name_calculation_latest}`,
        //     'order': 300, // actually should inherit from the metric itself
        //     [item['date_bom']] : eval(calculated_metric_recipie.calculation_latest)
        // }
        // TODO: actually perform the re-calculation here
        calculated_obj[`monthly___nosegment___${calculated_metric_recipie.id}`] = 666;// eval(calculated_metric_recipie.calculation_latest)
        calculated_obj[`${calculated_metric_recipie.id}_calculation_latest`] = eval(calculated_metric_recipie.calculation_latest);
        calculated_obj[`${calculated_metric_recipie.id}_calculation_previous`] = eval(calculated_metric_recipie.calculation_previous);
      }
    }
    if (calculation_or_formula === "formula") {
      for (let calculated_metric_recipie of Object.values(metrics_library)) {
        calculated_obj[`${calculated_metric_recipie.name}_formula_latest`] = (calculated_metric_recipie.formula_latest);
        calculated_obj[`${calculated_metric_recipie.name}_formula_previous`] = (calculated_metric_recipie.formula_previous);
        calculated_obj[`${calculated_metric_recipie.name}_definition_latest`] = (calculated_metric_recipie.definition_latest);
        calculated_obj[`${calculated_metric_recipie.name}_definition_previous`] = (calculated_metric_recipie.definition_previous);
      }
    }
    return calculated_obj;
  }

  // probably the move here is to simply replace the metric
  insertCalculatedMetricsKeysForPeriod(keys: any) {

    keys.push("contribution_margin_calcluation-latest");
  }

  insertCalculatedMetricsFormattingForPeriod(keys: any) {

    keys.push("contribution_margin_calcluation-latest");
  }

  generateMetricKeysFromRawData(dataObj: any, metric_type_string: any, metric_filter_key: any) {

    let filtered: any = []; // this all runs twice, first time through data is empty
    // console.log(dataObj);
    if (dataObj && dataObj.length > 0 && dataObj[0] && typeof dataObj[0] === "object") {
      filtered = Object.keys(dataObj[0]).map(key => {
        return key;
      }).filter(key => key.toLowerCase().includes(metric_filter_key));

      if (metric_type_string) {
        if (metric_type_string === "margins") {
          filtered = filtered
            .filter((key: any) => { return key.toLowerCase().includes("margin"); });
        } else if (metric_type_string === "incomestatement") {
          filtered = filtered
            .filter((key: any) => {
              return key.toLowerCase().includes("___" + metric_type_string) || key.toLowerCase().includes("margin");
            });
        } else {
          filtered = filtered
            .filter((key: any) => { return key.toLowerCase().includes("___" + metric_type_string); });

        }
      }
    }
    return filtered;
  }

  getPeriodFromContext(context: any) {
    return context?.period_month_quarter_year_3yr || context?.period || "MONTH";
  }

  getMetricTypeFromContext(context: any) {
    let return_metric_type = context?.metric_type;
    if (context?.metric_type) {
      let split_metric_type = context?.metric_type.split(":");
      if (split_metric_type.length > 0) {
        return_metric_type = split_metric_type[0].trim();
      }
    }
    return return_metric_type;
  }

  // todo: implement
  addBenchmarksData(pivoted: any) {
    return pivoted;
  }

  addBlankRowsToFinancialMetrics(pivoted: any) {
    let pivoted_with_blanks: any = [];
    for (let item of pivoted) {
      if ([
        "Cost of Goods Sold",
        "Fulfillment Costs",
        "G&A",
        "Operating Income (EBIT)",
        "Operating Income",
        "Other Expenses",
        "EBIT",
        "Fixed Assets (PPE)",
        "Current Liabilities",
        "Long Term Liabilities",
        "Common Stock",
        "Capital Expenditures - Purchase of PPE",
        "Equity Investment",
        "Net Cash Flow",

        "Direct Advertising",
        "Payroll"
      ].includes(item["Metric"])) {
        pivoted_with_blanks.push({});
        // pivoted_with_blanks.push(pivoted[4])
      }
      pivoted_with_blanks.push(item);
    }
    return pivoted_with_blanks;
  }

  // keys = insertCalculatedMetricsKeysForPeriod("income_statement", "quarter", "x", keys, "");
  applyFriendlyNamesAndFormatFinancialMetrics(dataLocalMetric: any, metadata_from_sql: any, metric_type: any, metric_filter_key: any) {
    let keys = this.generateMetricKeysFromRawData(dataLocalMetric, metric_type, metric_filter_key);
    let pivoted_obj: any = {};
    for (let item of dataLocalMetric) {
      for (let key of keys) {
        // let pretty_key = key.split("__REMOVE__")[0] //.replace("monthly___nosegment___incomestatement_","")
        // let id_key = key.split("__REMOVE__")[1] //.replace("monthly___nosegment___incomestatement_","")
        // if (!(id_key)){
        //     id_key = key
        // }
        // TODO: get rid of the crazy __REMOVE__ by getting data from the 'right' data table, by id then splice in friendly names from metadata
        // TODO: replace calculated metrics that need replacing??  or just append for ones that we want to show foroe periodicity?

        let indentLevel = 0;
        let order = 1000;
        // if (pretty_key in metadata['m-monthly']){
        //     indentLevel = metadata['m-monthly'][pretty_key]['indentLevel']
        //     order = metadata['m-monthly'][pretty_key]['rowNumber']
        // }
        let pretty_key = this.getFriendlyNameFromMetadata(key, metadata_from_sql);
        if (!pretty_key) {
          continue;
        }
        let metadata = metadata_from_sql.find((it: any) => {
          return key.toLowerCase() == it.metric.toLowerCase();
        });
        let prettyKeyWithIndent = pretty_key;
        let metadata_details: any = {};
        let positive_excel_format = "";
        let negative_excel_format = "";
        let number_type = "";
        if (metadata?.metadata_json) {
          try {
            metadata_details = JSON.parse(metadata?.metadata_json);
          } catch (e) {
            console.log(e);
          }

          const __ret = this.extractIndentLevelAndOrder(order, metadata_details, indentLevel, positive_excel_format, negative_excel_format);
          indentLevel = __ret.indentLevel;
          positive_excel_format = __ret.positive_excel_format;
          negative_excel_format = __ret.negative_excel_format;
          order = __ret.order;
        }

        if (!pivoted_obj[pretty_key]) {
          pivoted_obj[pretty_key] = {
            "Metric": prettyKeyWithIndent,
            "order": order
          };
        }
        pivoted_obj[pretty_key][item["date_bom"]] = this.formatAsExcelNumber(item[key], positive_excel_format, negative_excel_format);
      }
    }

    let pivoted = Object.keys(pivoted_obj).map(key => {
      return pivoted_obj[key];
    });
    return pivoted;
  }

  // TODO: implement some shit here
  formatAsExcelNumber(value: any, positive_excel_format: any, negative_excel_format: any) {
    return value;
  }

  // For wholesale, all the Metric names are the vendors like "Whole Foods Market"
  //  so we need to take the id itself "wholesale___nosegment___grosssalesperaccount_10"
  //  and deconstruct it to be Gross Sales Per Account - Whole Foods market
  getFriendlyNameFromMetadata(key: any, metadata: any) {
    let metadata_obj = metadata.find((it: any) => {
      return key.toLowerCase() == it.metric.toLowerCase();
    });
    let friendly_name = metadata_obj?.metric_friendly_name;
    if (key.startsWith("wholesale___")) {
      if (key.includes("grosssalesperaccount")) { friendly_name = "grosssalesperaccount - " + friendly_name;  } // POS Revenue
      else if (key.includes("doors")) { friendly_name = "doors - " + friendly_name;  } // Stores Selling
      else if (key.includes("monthlyunitspersku")) { friendly_name = "monthlyunitspersku - " + friendly_name;  } // Velocity (Units Sold Per SKU per Store per Week)
      else if (key.includes("skusperdoor")) { friendly_name = "skusperdoor - " + friendly_name;  } // SKUs per Store Selling
      else if (key.includes("unitsbysku")) { friendly_name = "unitsbysku - " + friendly_name;  } // Units Sold per SKU
      else if (key.includes("unitssold")) { friendly_name = "unitssold - " + friendly_name;  } // Units Sold
      else if (key.includes("wholesalelistprices")) { friendly_name = "wholesalelistprices - " + friendly_name;  } // Wholesale List Prices
      else if (key.includes("salesmix")) { friendly_name = "salesmix - " + friendly_name;  } // Wholesale List Prices
      else if (key.includes("retailprices")) { friendly_name = "retailprices - " + friendly_name;  } // Retail Prices -- not in model!!
      // Retailer Price ?
      else if (friendly_name?.replace(/\s/g, "") === "") { friendly_name = false; } // eliminate blank rows
      else { friendly_name = false; }
    }
    return friendly_name;
  }
  // TODO: as we get into calculated metrics, this can provide that
  insertCalculatedMetricsForPeriod(type: any, period: any, date_axes: any, data_source: any, compare_to: any) {
    // for (let item_id in data_source) {
    //   let calculated_metrics = this.buildCalculatedMetrics(data_source[item_id], "calculation");
    //   data_source[item_id] = {...data_source[item_id], ...calculated_metrics};
    // }
    return data_source;
  }

  compare(a: any, b: any) {
    if (Number(a.order) < Number(b.order)) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  }

  sortMetricsByOrderField(pivoted: any) {
    pivoted.sort(this.compare);
    // @ts-ignore
    return pivoted.map(({order: any, ...keepAttrs}) => keepAttrs);
  }

  addActualsToGlobals(global_data: any) {
    let returnDataArray = [];
    for (let item of global_data) {
      let metricName = item?.Metric;
      let actualsRow = this.GLOBALDATA.$.m_monthly_lastest_closed_financials_final.filter((row: any) => {
        return row["Metric"] == metricName;
      });

      let actualsRowMoM = this.GLOBALDATA.$.m_monthly_lastest_closed_financials_final_last_month.filter((row: any) => {
        return row["Metric"] == metricName;
      });

      let actualsRowYoY = this.GLOBALDATA.$.m_monthly_lastest_closed_financials_final_last_year.filter((row: any) => {
        return row["Metric"] == metricName;
      });

      if (actualsRow[0] && "Actuals" in actualsRow[0]) {
        item["Actuals"] = actualsRow[0]["Actuals"];
      } else {
        item["Actuals"] = 0;
      }

      if (actualsRowMoM[0] && "Month Over Month" in actualsRowMoM[0]) {
        item["Month Over Month"] = actualsRowMoM[0]["Month Over Month"];
      } else {
        item["Month Over Month"] = 0;
      }

      if (actualsRowYoY[0] && "Year Over Year" in actualsRowYoY[0]) {
        item["Year Over Year"] = actualsRowYoY[0]["Year Over Year"];
      } else {
        item["Year Over Year"] = 0;
      }

      returnDataArray.push(item);

    }
    return returnDataArray;
  }

  // FUNCTIONS TO GENERATE HTML

  handleColorBasedOnTheValue(value: number, keyMetric: string, icon: string, noDataToShow: boolean = false) {
    const inverseMetricKey = [
      "Discount Rate", "Returns Rate",
      "Cash Conversion Cycle", "Fully Loaded Cost per Acquired Customer"
    ];

    if (value > 0 && !inverseMetricKey.includes(keyMetric)) {
      return `<span style="padding: 4px 8px; border-radius: 20px; background: #a2cdf4; margin-right: 5px">
                <i class="fa-solid ${icon}" style="color: green; padding-right: 5px"></i>
                 ${noDataToShow ? "" : `${(value * 100).toFixed(2)}%`}
              </span>`;
    }

    if (value === 0) {
      return `<span style="padding: 4px 8px; display: inline-block; text-align: center; border-radius: 20px; background: #a2cdf4; margin-right: 5px">
                <i class="fa-solid ${icon}" style="padding: 0 5px"></i>
              </span>`;
    }

    return `<span style="padding: 4px 8px; border-radius: 20px; background: #a2cdf4; margin-right: 5px">
                <i class="fa-solid ${icon}" style="color: red; padding-right: 5px"></i>
                ${noDataToShow ? "" : `${(value * 100).toFixed(2)}%`}
              </span>`;

  }

  handleGenerateHTMLRow(metricKey: string, icon: string, text: string, index: number, isLastRow: boolean = false) {
    const defaultColors = [
      "#5b8dd8", "#e1bd3d", "#6fa9ea", "#a2cdf4", "#3e4d91",
      "#ecd886", "#527bc5", "#629be6", "#82b9ee", "#c4dff8",
      "#d76c13", "#db9624", "#4b6ab2", "#ddb32f", "#e5ca59",
      "#f3e7b5", "#d98820", "#dca729", "#333333", "#8B8B8B"
    ];
    const styles = {
      rowTitleStyle: "font-size: 12px; margin-right: 5px; display: inline-block; min-width: 100px"
    };
    return `<div style="height: 25px; display: flex; align-items: center">
            <span style="display: inline-block; height: 5px; width: 5px; border-radius: 100%; margin-right: 10px; background: ${defaultColors[index]}"></span>
            <span style="${styles.rowTitleStyle}">${metricKey}
            ${text}${icon}</span>
           </div><hr style="border:none; height: 1px; background-color: rgba(0,0,0,.2); display: ${isLastRow ? "none" : "block"}"/>`;
  }

  buildMetricCommentaryHTML(dataMulti: any, date_key: any) {
    if (!dataMulti || !dataMulti.length) { // data can be [] so dont proceed and get a console error..
      return [];
    }
    let sorted = this.sortMetricsByOrderField(dataMulti).reverse();

    // figure out many keys here
    let alerts_object: any = {};
    let metricKeys = Object.keys(sorted[0]).filter((key) => key != date_key);
    for (let [index, metricKey] of metricKeys.entries()) {

      let dataInOrder = [];
      for (let point of sorted) {
        dataInOrder.push(point[metricKey]);
      }

      let thisMonth = dataInOrder[0];
      let lastMonth = dataInOrder[1];
      let twoMonthsAgo = dataInOrder[2];
      let threeMonthsAgo = dataInOrder[3];
      let fourMonthsAgo = dataInOrder[4];

      let trailing3MonthAvg = (lastMonth + twoMonthsAgo + threeMonthsAgo) / 3;
      let thisMonthVstrailing3MonthAvgPercent = (thisMonth - trailing3MonthAvg) / Math.abs(trailing3MonthAvg);
      let thisVslastMonthPercent = (thisMonth - lastMonth) / Math.abs(lastMonth);
      let lastVsTwoMonthsAgoPercent = (lastMonth - twoMonthsAgo) / Math.abs(twoMonthsAgo);
      let twoMonthsAgoVsThreeMonthsAgoPercent = (twoMonthsAgo - threeMonthsAgo) / Math.abs(threeMonthsAgo);
      let threeMonthsAgoVsFourMonthsAgoPercent = (threeMonthsAgo - fourMonthsAgo) / Math.abs(fourMonthsAgo);
      let thisVsThreeMonthsAgoPercent = (thisMonth - threeMonthsAgo) / Math.abs(threeMonthsAgo);

      // metricKey = metricKey.split(":")[0]; // hack for formatter
      let alerts: any = [];
      if ((thisMonth >= 0 && thisMonthVstrailing3MonthAvgPercent > 0.1) || (thisMonth < 0 && thisMonthVstrailing3MonthAvgPercent > 0.1)) {
        alerts.push(
          this.handleGenerateHTMLRow(
            metricKey,
            this.handleColorBasedOnTheValue(thisMonthVstrailing3MonthAvgPercent, metricKey, "fa-arrow-trend-up"),
            "has increased from the trailing three month average ",
            index,
            index === (metricKeys.length - 1)
          ));
      } else if ((thisMonth > 0 && thisMonthVstrailing3MonthAvgPercent < -0.1) || (thisMonth <= 0 && thisMonthVstrailing3MonthAvgPercent < -0.1)) {
        alerts.push(
          this.handleGenerateHTMLRow(
            metricKey,
            this.handleColorBasedOnTheValue(thisMonthVstrailing3MonthAvgPercent, metricKey, "fa-arrow-trend-down"),
            "has decreased from the trailing three month average ",
            index,
            index === (metricKeys.length - 1)
          ));
      }

      if (thisVslastMonthPercent > 0.1) {
        alerts.push(
          this.handleGenerateHTMLRow(
            metricKey,
            this.handleColorBasedOnTheValue(thisVslastMonthPercent, metricKey, "fa-arrow-trend-up"),
            "has greatly increased since last month ",
            index,
            index === (metricKeys.length - 1)
          ));
      } else if (thisVslastMonthPercent < -0.1) {
        alerts.push(
          this.handleGenerateHTMLRow(
            metricKey,
            this.handleColorBasedOnTheValue(thisVslastMonthPercent, metricKey, "fa-arrow-trend-down"),
            "has greatly decreased since last month ",
            index,
            index === (metricKeys.length - 1)
          ));
      }

      if (thisVslastMonthPercent < -0.05 && lastVsTwoMonthsAgoPercent < -0.05 && twoMonthsAgoVsThreeMonthsAgoPercent < -0.05 && thisVsThreeMonthsAgoPercent) {
        alerts.push(
          this.handleGenerateHTMLRow(
            metricKey,
            this.handleColorBasedOnTheValue(thisVsThreeMonthsAgoPercent, metricKey, "fa-arrow-trend-down"),
            "has decreased by over 5 percent for three consecutive months ",
            index,
            index === (metricKeys.length - 1)
          ));
      } else if (thisVslastMonthPercent > 0.05 && lastVsTwoMonthsAgoPercent > 0.05 && twoMonthsAgoVsThreeMonthsAgoPercent > 0.05 && thisVsThreeMonthsAgoPercent) {
        alerts.push(
          this.handleGenerateHTMLRow(
            metricKey,
            this.handleColorBasedOnTheValue(thisVsThreeMonthsAgoPercent, metricKey, "fa-arrow-trend-up"),
            "has increased by over 5 percent for three consecutive months ",
            index,
            index === (metricKeys.length - 1)
          ));
      } else if (thisVslastMonthPercent < 0.0 && lastVsTwoMonthsAgoPercent < 0.0 && twoMonthsAgoVsThreeMonthsAgoPercent < 0.0  && thisVsThreeMonthsAgoPercent) {
        alerts.push(
          this.handleGenerateHTMLRow(
            metricKey,
            this.handleColorBasedOnTheValue(thisVsThreeMonthsAgoPercent, metricKey, "fa-arrow-trend-down", false),
            "has decreased for three consecutive months ",
            index,
            index === (metricKeys.length - 1)
          ));
      } else if (thisVslastMonthPercent > 0.0 && lastVsTwoMonthsAgoPercent > 0.0 && twoMonthsAgoVsThreeMonthsAgoPercent > 0.0  && thisVsThreeMonthsAgoPercent) {
        alerts.push(
          this.handleGenerateHTMLRow(
            metricKey,
            this.handleColorBasedOnTheValue(thisVsThreeMonthsAgoPercent, metricKey, "fa-arrow-trend-up", false),
            "has increased for three consecutive months ",
            index,
            index === (metricKeys.length - 1)
          ));
      }
      // if nothing here, just show a 'value is up or down this much'
      // cases here...
      // value 5, 10%   went from 4.5 to 5 increase
      // value 5, -10%  went from 5 to 4.5 decrease
      // value -5, 10%  went from -5 to -4.5 -> increase
      // value -5, -10%  went from -5 to -5.5 -> decrease
      if (!alerts.length) {
        if ((thisMonth >= 0 && thisVslastMonthPercent > 0) || (thisMonth < 0 && thisVslastMonthPercent > 0)) {
          alerts.push(
            this.handleGenerateHTMLRow(
              metricKey,
              this.handleColorBasedOnTheValue(thisVslastMonthPercent, metricKey, "fa-arrow-trend-up"),
              "has increased for this period ",
              index,
              index === (metricKeys.length - 1)
            ));
        } else if ((thisMonth < 0 && thisVslastMonthPercent < 0) || (thisMonth >= 0 && thisVslastMonthPercent < 0)) {
          alerts.push(
            this.handleGenerateHTMLRow(
              metricKey,
              this.handleColorBasedOnTheValue(thisVslastMonthPercent, metricKey, "fa-arrow-trend-down"),
              "has decreased for this month ",
              index,
              index === (metricKeys.length - 1)
            ));
        } else {
          alerts.push(this.handleGenerateHTMLRow(
            metricKey,
            this.handleColorBasedOnTheValue(0, metricKey, "fa-minus"),
            "has not changed since last month ",
            index,
            index === (metricKeys.length - 1)));
        }
      }

      if (alerts.length > 1) {
        alerts = alerts.map((alert: string, index: number) => (index !== alerts.length - 1) ? alert.replace(/<hr\s*[^>]*\/?>/gi, "") : alert);
      }

      alerts_object[metricKey] = alerts;
    }

    return alerts_object;
  }

  formatMetricCommentaryHTML(alertsObject: any) {
    let return_string = "";
    const gradientTextColorStyle = `
    background: linear-gradient(90.61deg, #75DDE1 0.27%, #4E46BE 92.65%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;`;
    if (alertsObject && Object.keys(alertsObject).length) {
      return_string = return_string + "<h4 style='margin: 0'>Report Insights</h4>";
      return_string = return_string + `
        <p style='margin: 0.1rem 0 0.5rem 0; color: #8B8B8B; font-size: 12px'>Generated automatically by
            <span class="dazzler-no-print" style="${gradientTextColorStyle}">Drivepoint Intelligence &#8482;</span>
            <span class="dazzler-print" style="display: none">Drivepoint Intelligence &#8482;</span>
        </p>`;
      for (let key in alertsObject) {
        if (return_string !== "") {
          return_string = `${return_string}\n`;
        }
        return_string = return_string + alertsObject[key]?.join("\n");
      }
    }
    return return_string;

  }

  // ///// BELOW FUNCTIONS FOR ADDING COMNMENTARY
  buildMetricCommentary(dataMulti: any, date_key: any) {
    if (!dataMulti || !dataMulti.length) { // data can be [] so dont proceed and get a console error..
      return [];
    }
    let sorted = this.sortMetricsByOrderField(dataMulti).reverse();

    // figure out many keys here
    let alerts_object: any = {};
    let metricKeys = Object.keys(sorted[0]).filter((key) => key != date_key);
    for (let metricKey of metricKeys) {

      let dataInOrder = [];
      for (let point of sorted) {
        dataInOrder.push(point[metricKey]);
      }

      let thisMonth = dataInOrder[0];
      let lastMonth = dataInOrder[1];
      let twoMonthsAgo = dataInOrder[2];
      let threeMonthsAgo = dataInOrder[3];
      let fourMonthsAgo = dataInOrder[4];

      let trailing3MonthAvg = (lastMonth + twoMonthsAgo + threeMonthsAgo) / 3;
      let thisMonthVstrailing3MonthAvgPercent = (thisMonth - trailing3MonthAvg) / Math.abs(trailing3MonthAvg);
      let thisVslastMonthPercent = (thisMonth - lastMonth) / Math.abs(lastMonth);
      let lastVsTwoMonthsAgoPercent = (lastMonth - twoMonthsAgo) / Math.abs(twoMonthsAgo);
      let twoMonthsAgoVsThreeMonthsAgoPercent = (twoMonthsAgo - threeMonthsAgo) / Math.abs(threeMonthsAgo);
      let threeMonthsAgoVsFourMonthsAgoPercent = (threeMonthsAgo - fourMonthsAgo) / Math.abs(fourMonthsAgo);

      metricKey = metricKey.split(":")[0]; // hack for formatter
      let alerts: any = [];
      if ((thisMonth >= 0 && thisMonthVstrailing3MonthAvgPercent > 0.1) || (thisMonth < 0 && thisMonthVstrailing3MonthAvgPercent < -0.1)) {
        alerts.push(`- **${metricKey}** for this Month is above the trailing 3 month average by ${(100 * thisMonthVstrailing3MonthAvgPercent).toFixed(2)}% \n`);
      } else if ((thisMonth < 0 && thisMonthVstrailing3MonthAvgPercent > 0.1) || (thisMonth >= 0 && thisMonthVstrailing3MonthAvgPercent < -0.1)) {
        alerts.push(`- **${metricKey}** for this Month is below the trailing 3 month average by ${(-100 * thisMonthVstrailing3MonthAvgPercent).toFixed(2)}% \n`);
      }

      if (thisVslastMonthPercent > 0.1) {
        alerts.push(`- **${metricKey}** has GREATLY increased ${(100 * thisVslastMonthPercent).toFixed(2)}% since last month \n`);
      } else if (thisVslastMonthPercent < -0.1) {
        alerts.push(`- **${metricKey}** has GREATLY decreased by ${(-100 * thisVslastMonthPercent).toFixed(2)}% since last month \n`);
      }

      if (thisVslastMonthPercent < -0.05 && lastVsTwoMonthsAgoPercent < -0.05 && twoMonthsAgoVsThreeMonthsAgoPercent < -0.05) {
        alerts.push(`- **${metricKey}** has decreased by 5% three consecutive months \n`);
      } else if (thisVslastMonthPercent > 0.05 && lastVsTwoMonthsAgoPercent > 0.05 && twoMonthsAgoVsThreeMonthsAgoPercent > 0.05) {
        alerts.push(`- **${metricKey}** has increased by 5% three consecutive months \n`);
      } else if (thisVslastMonthPercent < -0.0 && lastVsTwoMonthsAgoPercent < -0.0 && twoMonthsAgoVsThreeMonthsAgoPercent < -0.0) {
        alerts.push(`- **${metricKey}** has decreased three consecutive months \n`);
      } else if (thisVslastMonthPercent > 0.0 && lastVsTwoMonthsAgoPercent > 0.0 && twoMonthsAgoVsThreeMonthsAgoPercent > 0.0) {
        alerts.push(`- **${metricKey}** has increased three consecutive months \n`);
      }
      // if nothing here, just show a 'value is up or down this much'
      // cases here...
      // value 5, 10%   went from 4.5 to 5 increase
      // value 5, -10%  went from 5 to 4.5 decrease
      // value -5, 10%  went from -5 to -4.5 -> increase
      // value -5, -10%  went from -5 to -5.5 -> decrease
      if (!alerts.length) {
        if ((thisMonth >= 0 && thisVslastMonthPercent > 0) || (thisMonth < 0 && thisVslastMonthPercent > 0)) {
          alerts.push(`- **${metricKey}** for this Month has increased by ${(100 * thisVslastMonthPercent).toFixed(2)}% \n `);
        } else if ((thisMonth < 0 && thisVslastMonthPercent < 0) || (thisMonth >= 0 && thisVslastMonthPercent < 0)) {
          alerts.push(`- **${metricKey}** for this Month has decreased by ${(100 * thisVslastMonthPercent).toFixed(2)}% \n `);
        } else {
          alerts.push(`- **${metricKey}** for this Month is unchanged \n`);
        }
      }
      alerts_object[metricKey] = alerts;
    }
    return alerts_object;
  }

  formatMetricCommentary(alertsObject: any) {
    let return_string = "";
    if (alertsObject && Object.keys(alertsObject).length) {
      return_string = return_string + "## Report Intelligence \n";
      return_string = return_string + "# Powered by Drivepoint's AI \n";
      for (let key in alertsObject) {
        if (return_string !== "") {
          return_string = `${return_string}\n`;
        }
        return_string = return_string + alertsObject[key]?.join("\n");
      }
    }
    return return_string;

  }

  buildMetricAsClause(metric_array: any) {
    if (!Array.isArray(metric_array)) {
      metric_array = [metric_array];
    }
    return metric_array.map((metric: any) => {
      return "`" + metric + "`";
    }
    ).join(",\n");
  }

  // ////// BELOW FUNCTIONS TO GENERATE PIVOTED COHORT REPORT

  formatPivotedCohortData(pivoted_cohort_data: any, key: any) {
    let returndata: any = [];
    if (!this.GLOBALDATA?.$?.fpm) {
      throw new Error("$.fpm is a required input");
    }
    if (this.GLOBALDATA?.$?.fpm.length === 0) {
      throw new Error("$.fpm is a required input, and cannot be empty");
    }

    let overall_max_month_object = this.GLOBALDATA?.$?.fpm?.reduce((prev: any, current: any) => {
      return (prev && prev?.max_months_since_first_purchase > current?.max_months_since_first_purchase) ? prev : current;
    });
    let max_month_map: any = {};
    this.GLOBALDATA?.$?.fpm?.forEach((current: any) => {
      max_month_map[current?.cohort_month] = current?.max_months_since_first_purchase;
    });
    let numberSpine = Array.from({length: overall_max_month_object?.max_months_since_first_purchase + 1}, (value, index) => index);
    for (let obj of pivoted_cohort_data) {
      let newObj: any = {};
      newObj[key] = obj[key]; // to keep it in order
      for (let num of numberSpine) {
        newObj[num] = obj[num] ? obj[num] :
          num < max_month_map[obj[key]] ? 0 : undefined;
      }
      returndata.push(newObj);
    }
    return this.sortMetricsByDateField(returndata, key);
  }

  // take strings like '2022-01-01' or whatever and make them sort
  // don't assume it's in first position, so find a valid date column first
  compareDate(a: any, b: any) {
    let index: any = 0;
    for (let d in a) {
      if (dayjs(a[d], "YYYY-MM-DD", true).isValid()) {
        index = d;
      }
    }

    let dateA = new Date(a[index]);
    let dateB = new Date(b[index]);

    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  }

  sortMetricsByDateField(sortable: any, key: any) {
    sortable.sort(this.compareDate);
    // @ts-ignore
    return sortable.map(({date, ...keepAttrs}) => keepAttrs);
  }

  // TODO: unused now, but from inventory Monthly table.. pivot function
  //     $.data = pivotDataOnAxis($.data,$CONTEXT.inventory_monthly_metrics)
  // // return data
  //     function pivotDataOnAxis(dataObj, namedMetric) {
  //
  //         returnDataArray = [{
  //             "Month": "2023-01-01",
  //             "Total Inventory: Finished Goods": 74070.93999999999
  //         }, {
  //             "Month": "2023-02-01",
  //             "Total Inventory: Finished Goods": 74070.93999999999
  //         },
  //             {
  //                 "Month": "2023-03-01",
  //                 "Total Inventory: Finished Goods": 74070.93999999999
  //             },
  //             {"Month": "2023-04-01",
  //                 "Total Inventory: Finished Goods": 74070.93999999999
  //             }
  //         ]
  //
  //         // returnDataArray.push({"2023-02-15":660000})
  //         returnDataArray = []
  //
  //         for (d of dataObj ){
  //             keys = Object.keys(d)
  //             for (let key of keys){
  //                 if (key != "Metric"){
  //                     returnDataObject = {
  //                         "Month": key,
  //                         [namedMetric] : d[key]
  //                     }
  //                     returnDataArray.push(returnDataObject)
  //                 }
  //             }
  //         }
  //         // returnDataArray.push(returnDataObject)
  //         console.log(returnDataArray)
  //         return returnDataArray
  //     }

  // /////  BELOW to generate PandL Bar chart

  pivotForGraphingFinancialData(pivoted: any) {
    let pivoted_ret_array: any = [];
    // let metricRow = pivoted.map((row) => {
    //   console.log(row);
    // });
    if (!pivoted) {
      throw new Error("$.data required input");
    }

    let date_obj: any = {};
    for (let row of pivoted) {
      let keys = Object.keys(row);
      let metric_name = row["Metric"];
      for (let key of keys) {
        if (key !== "Metric") {
          if (!date_obj[key]) {
            date_obj[key] = {};
          }
          date_obj[key][metric_name] = row[key];
        }
      }
    }

    // now format for graphing
    // for (let key in date_obj) {
    //   date_obj[key]['Date'] = key
    //   pivoted_ret_array.push(date_obj[key])
    // }
    for (let key in date_obj) {
      let new_obj: any = {};
      new_obj["Date"] =  key;
      new_obj = {...new_obj, ...date_obj[key]};
      // date_obj[key]['Date'] = key
      pivoted_ret_array.push(new_obj);
    }

    return pivoted_ret_array;
  }

  /*
      For percent values 0 decimals, no commas
          formatter : {
          type: "percent",
          notation: "standard",
          grouping: false,
          decimals: 0
        }
      For Currency values no decimals, with commas, and financial format ie negatives in ()
          formatter : {
          type: "currency",
          notation: "financial",
          grouping: true,
          decimals: 0
        }

   */
  formatRedGreenForPositiveNegativeValues(metric_to_gradient: any, first_row_key: any, type: any, notation: any, decimals: number, grouping: any) {
    let raw_data = this.GLOBALDATA?.$?.data;
    for (let metric_object of raw_data) {
      let value = metric_object[metric_to_gradient];
      let color = "black";
      if (value < 0) {
        color = "red";
      } else if (value > 0) {
        color = "green";
      }
      let cell_metric_structure = {
        column: metric_to_gradient,
        rowName: first_row_key,
        rowValue: metric_object[first_row_key],
        formatter: {
          type,
          decimals,
          notation,
          grouping,
          style: {
            "color": color
          }
        }
      };
      this.GLOBALDATA.$.formatter?.cells?.formatters.push(cell_metric_structure);
    }
  }

  /*
USAGE:
In Dazzler
$LIB.JS.pivotFromChartFormatToTable("Date", "Metric");

ie this is a no-op
$LIB.JS.pivotFromChartFormatToTable("Date", "Metric");
$LIB.JS.pivotFromTableFormatToChart("Metric", "Date");

Locally:
$.data = pivotFromChartFormatToTable($.data, "Date", "Metric")
data : send in $.data and it will get re-assigned
pivot_key: what is the date key named in your pre-pivoted report? ie "Date" or "MONTH"
new_pivot_key: where do you want the keys from the object to end up in the pivoted report, ie whats the column header for the new column 0? "Metric" "Plan" ,etc..

PIVOTS the format typically used for GRAPHIING to A TABLE:
FROM:
[
    {
        "Date": "2023-02-01",
        "Gross Sales DTC": 574470.88,
        "Gross Sales Marketplace": 516021.97,
        "Gross Sales Retail": 0,
        "Gross Sales Wholesale": 72032.88
    },
    {
        "Date": "2023-03-01",
        "Gross Sales DTC": 697745.61,
        "Gross Sales Marketplace": 610771.44,
        "Gross Sales Retail": 0,
        "Gross Sales Wholesale": 69486.53
    },
    {
        "Date": "2023-04-01",
        "Gross Sales DTC": 670277.71,
        "Gross Sales Marketplace": 603713.4,
        "Gross Sales Retail": 0,
        "Gross Sales Wholesale": 49526.8
    },
    {
        "Date": "2023-05-01",
        "Gross Sales DTC": 647968.69,
        "Gross Sales Marketplace": 633734.11,
        "Gross Sales Retail": 0,
        "Gross Sales Wholesale": 69750.04
    },
    {
        "Date": "2023-06-01",
        "Gross Sales DTC": 651843.58,
        "Gross Sales Marketplace": 632797.43,
        "Gross Sales Retail": 0,
        "Gross Sales Wholesale": 62205.02
    },
    {
        "Date": "2023-07-01",
        "Gross Sales DTC": 657822.33,
        "Gross Sales Marketplace": 697697.77,
        "Gross Sales Retail": 0,
        "Gross Sales Wholesale": 65901.94
    },
    {
        "Date": "2023-08-01",
        "Gross Sales DTC": 560948.63,
        "Gross Sales Marketplace": 530548.97,
        "Gross Sales Retail": 0,
        "Gross Sales Wholesale": 1003045.64
    },
    {
        "Date": "2023-09-01",
        "Gross Sales DTC": 478987.08,
        "Gross Sales Marketplace": 474481.7,
        "Gross Sales Retail": 0,
        "Gross Sales Wholesale": 54873.88
    },
    {
        "Date": "2023-10-01",
        "Gross Sales DTC": 493853.16,
        "Gross Sales Marketplace": 575572.73,
        "Gross Sales Retail": 0,
        "Gross Sales Wholesale": 30585.18
    }
]

TO:
[
  {
    Metric: 'Gross Sales DTC',
    '2023-02-01': 574470.88,
    '2023-03-01': 697745.61,
    '2023-04-01': 670277.71,
    '2023-05-01': 647968.69,
    '2023-06-01': 651843.58,
    '2023-07-01': 657822.33,
    '2023-08-01': 560948.63,
    '2023-09-01': 478987.08,
    '2023-10-01': 493853.16
  },
  {
    Metric: 'Gross Sales Marketplace',
    '2023-02-01': 516021.97,
    '2023-03-01': 610771.44,
    '2023-04-01': 603713.4,
    '2023-05-01': 633734.11,
    '2023-06-01': 632797.43,
    '2023-07-01': 697697.77,
    '2023-08-01': 530548.97,
    '2023-09-01': 474481.7,
    '2023-10-01': 575572.73
  },
  {
    Metric: 'Gross Sales Retail',
    '2023-02-01': 0,
    '2023-03-01': 0,
    '2023-04-01': 0,
    '2023-05-01': 0,
    '2023-06-01': 0,
    '2023-07-01': 0,
    '2023-08-01': 0,
    '2023-09-01': 0,
    '2023-10-01': 0
  },
  {
    Metric: 'Gross Sales Wholesale',
    '2023-02-01': 72032.88,
    '2023-03-01': 69486.53,
    '2023-04-01': 49526.8,
    '2023-05-01': 69750.04,
    '2023-06-01': 62205.02,
    '2023-07-01': 65901.94,
    '2023-08-01': 1003045.64,
    '2023-09-01': 54873.88,
    '2023-10-01': 30585.18
  }
]

 */

  pivotFromChartFormatToTable(unpivoted: any, pivot_key: any, new_first_column_key: any) {
    let pivoted_ret_array: any = [];
    // let metricRow = pivoted.map((row) => {
    //   console.log(row);
    // });
    if (!unpivoted) {
      throw new Error("$.data required input");
    }
    let date_obj: any = {};
    for (let row of unpivoted) {
      let keys = Object.keys(row);
      let metric_name = row[pivot_key];
      for (let key of keys) {
        if (key !== pivot_key) {
          if (!date_obj[key]) {
            date_obj[key] = {};
          }
          date_obj[key][metric_name] = row[key];
        }
      }
    }

    for (let key in date_obj) {
      let new_obj: any = {};
      new_obj[new_first_column_key] =  key;
      new_obj = {...new_obj, ...date_obj[key]};
      // date_obj[key]['Date'] = key
      pivoted_ret_array.push(new_obj);
    }

    return pivoted_ret_array;
  }

  /*
does the inverse transform of  pivotFromChartFormatToTable
ie this is a no-op
$LIB.JS.pivotFromChartFormatToTable("Date", "Metric");
$LIB.JS.pivotFromTableFormatToChart("Metric", "Date");

 */
  pivotFromTableFormatToChart(unpivoted: any, pivot_key: any, new_first_column_key: any) {
    let pivoted_ret_array: any = [];
    if (!unpivoted) {
      throw new Error("$.data required input");
    }
    let date_obj: any = {};
    for (let row of unpivoted) {
      let keys = Object.keys(row);
      let metric_name = row[pivot_key];
      for (let key of keys) {
        if (key !== pivot_key) {
          if (!date_obj[key]) {
            date_obj[key] = {};
          }
          date_obj[key][new_first_column_key] = key;
          date_obj[key][metric_name] = row[key];
        }
      }
    }

    for (let key in date_obj) {
      let new_obj: any = {};
      new_obj[new_first_column_key] =  key;
      new_obj = {...new_obj, ...date_obj[key]};
      pivoted_ret_array.push(new_obj);
    }

    return pivoted_ret_array;
  }

  /*
  USAGE:
  (axisSide:any, minMultiplier:any, maxMultiplier:any, metric_key:any, min_override:any, max_override:any)
  axisSide:any, - 'left' or 'right'
  minMultiplier:any, - multiply the minimum value by this number and set the minimum of the axis to this.
  maxMultiplier:any, - multiply the minimum value by this number and set the maximum of the axis to this.
  metric_key:any, - what metric are we graphing, and using to calculate min/max
  min_override:any, - override of min, ie you can set to 0, -100 etc..
  max_override:any  - override of max, ie you can set to 0, 100, etc
  EXAMPLE CALLS
  formatAxisMinAndMaxByMultiplier('left', 1, 1.5,"Total Marketing Expenses DTC", false, false);
  formatAxisMinAndMaxByMultiplier('right', 1, 1,"Fully Loaded CAC DTC",0, false);

 */
  formatAxisMinAndMaxByMultiplier(axisSide: any, minMultiplier: any, maxMultiplier: any, metric_key: any, min_override: any, max_override: any) {
    let min_value = Math.min(...this.GLOBALDATA.$.data.map((o: any) => o[metric_key]));
    let max_value = Math.max(...this.GLOBALDATA.$.data.map((o: any) => o[metric_key]));
    let min = Number(min_override) || min_override === 0 ?  min_override : min_value * minMultiplier;
    let max = Number(max_override) || max_override === 0 ?  max_override : max_value * maxMultiplier;
    this.customizeAxisMinAndMax(axisSide, min, max);
  }
  // all props avaliable for "right" and "left" https://developers.google.com/chart/interactive/docs/customizing_axes
  customizeAxisMinAndMax(axisSide: any, min: any, max: any) {
    if (!this.GLOBALDATA.$?.formatter) {
      this.GLOBALDATA.$.formatter = {};
    }
    if (!this.GLOBALDATA.$?.formatter?.vAxes) {
      this.GLOBALDATA.$.formatter.vAxes = {};
    }
    if (!this.GLOBALDATA.$.formatter.vAxes?.[axisSide]) {
      this.GLOBALDATA.$.formatter.vAxes[axisSide] = {};
    }
    this.GLOBALDATA.$.formatter.vAxes[axisSide].viewWindowMode = "explicit";
    this.GLOBALDATA.$.formatter.vAxes[axisSide].viewWindow = {
      min: min,
      max: max
    };
  }

  getColorGradientForRangeValues(range: number[], value: number, colors: (string)[]): Record<string, string> {
    return {
      background: range.indexOf(value) > -1 ? colors[range.indexOf(value)] : "transparent"
    };
  }

  generateBuckets(values: number[], mid_point: number) {
    return [values.filter(value => value < mid_point), values.filter(value => value > mid_point)];
  }

  generateColorsForEachBucket(bucketLength: number, bucketIndex: number, colors: string[]): string[] {
    const addExtraColor = bucketIndex === 0;
    const gradientColors = new Gradient()
      .setColorGradient(...colors.slice(bucketIndex, bucketIndex + 2))
      .setMidpoint(bucketLength + (addExtraColor ? 1 : 0))
      .getColors();

    if (addExtraColor) {
      gradientColors.pop();
    }
    return gradientColors;
  }

  handleColorRangeWithMidPoint(colors: string[], includeCols: string[] = [], excludeCols: string[], mid_point: number = 0, formatter: Record<string, any> = {}) {

    if (!this.GLOBALDATA.$?.formatter) {
      this.GLOBALDATA.$.formatter = {};
    }

    if (!this.GLOBALDATA.$?.formatter?.cells) {
      this.GLOBALDATA.$.formatter.cells = {formatter: {}, formatters: []};
    }

    let keys = Object.keys(this.GLOBALDATA.$.data[0]);

    if (includeCols.length > 0) {
      keys = keys.filter(key => includeCols.includes(key.trim()));
    }

    if (excludeCols.length > 0) {
      keys = keys.filter(key => !excludeCols.includes(key.trim()));
    }

    let gradientCellFormatters: CellFormatterForGradient[] = [];
    (this.GLOBALDATA.$.data ?? []).forEach((row: Record<string, any>) => {
      keys.forEach(key => {
        if (typeof row[key] === "number") {
          gradientCellFormatters.push({column: key, rowName: key, rowValue: row[key], formatter: {}});
        }
      });
    });
    gradientCellFormatters.sort((el1, el2) => el1.rowValue - el2.rowValue);

    const uniqueValuesSplitByMidpoint = this.generateBuckets(Array.from(new Set(gradientCellFormatters.map(el => el.rowValue)).values()), mid_point);
    const colorsMap = new Map();

    uniqueValuesSplitByMidpoint.forEach((bucket, index) => {
      const generateColors = this.generateColorsForEachBucket(bucket.length, index, colors);
      generateColors.forEach((generatedColor, index) => {
        colorsMap.set(bucket[index], generatedColor);
      });
    });

    // midpoint value should have midpoint color;
    colorsMap.set(mid_point, colors[1]);

    gradientCellFormatters.forEach((el, index) =>
      gradientCellFormatters[index] = {
        ...el,
        formatter: {
          ...formatter,
          style: {
            ...(formatter?.style ?? {}),
            background: colorsMap.get(el.rowValue),
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "right"
          }
        }
      });
    this.GLOBALDATA.$.formatter.cells.formatters = [...this.GLOBALDATA.$.formatter.cells.formatters, ...gradientCellFormatters];

  }

  handleColorRange(colors: string[], columns: string[] = [], formatter: Record<string, any> = {}) {

    if (!this.GLOBALDATA.$?.formatter) {
      this.GLOBALDATA.$.formatter = {};
    }

    if (!this.GLOBALDATA.$?.formatter?.cells) {
      this.GLOBALDATA.$.formatter.cells = {formatter: {}, formatters: []};
    }

    let rangesObj: Record<string, any> = Object.assign({}, ...Object.keys(this.GLOBALDATA.$.data[0]).map(key => ({[key]: []})));
    this.GLOBALDATA.$.data.forEach((row: Record<string, any>) => {
      Object.keys(rangesObj).forEach(key => {
        if (!rangesObj[key].includes(row[key]))
          rangesObj[key].push(row[key]);
      });
    });

    Object.keys(rangesObj).forEach(key => {
      rangesObj[key] = {
        data: rangesObj[key].filter((it: any) => it !== undefined).sort((a: number, b: number) => a - b),
        colors: new Gradient().setColorGradient(...colors).setMidpoint(rangesObj[key].filter((it: any) => it !== undefined).length).getColors()
      };
    });

    const rangeStyles = (this.GLOBALDATA.$.data ?? [])
      .map(
        (row: Record<string, any>) =>
          Object.keys(row)
            .filter(col => {
              if (typeof row[col] !== "number") { return false; }
              if (columns.length > 0) { return columns.includes(col.trim()); }
              return col;
            })
            .map(col => (
              {
                column: col,
                rowName: col,
                rowValue: row[col],
                formatter: {
                  ...formatter,
                  style: {
                    ...(formatter?.style ?? {}),
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "right",
                    ...this.getColorGradientForRangeValues(rangesObj[col].data, row[col], rangesObj[col].colors)
                  }
                }
              }
            ))
      ).flat();
    this.GLOBALDATA.$.formatter.cells.formatters = [...this.GLOBALDATA.$.formatter.cells.formatters, ...rangeStyles];
  }

  /*

  this makes cells orange, use to build a gradient...
$.formatter?.cells?.formatters.push(
    {
        column: "Variance Percent",
        rowPattern: '*',

        formatter : {
        style: {
            background: "orange",
            width:"100%",
            height:"100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "right"
        },
          type: "percent",
          notation: "standard",
          grouping: false,
          decimals: 0
        }
    }
);

$.formatter?.columns?.formatters.push(
    {
        column: "Variance Percent",
        formatter :{
            width: 150,
        }
    }
);

$.formatter?.cells?.formatters.push(
    {
        column: "Variance Percent",
        rowPattern: '*',

        formatter : {
        style: {
            background: "orange",
            width:"100%",
            height:"100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "right"
        },
          type: "percent",
          notation: "standard",
          grouping: false,
          decimals: 0
        }
    }
);

$.formatter?.cells?.formatters.push(
    {
        column: "Variance Percent",
        rowName: 'Metric',
        rowValue: 'Gross Sales: DTC Online',

        formatter : {
        style: {
            background: "rgba(60,179,113,0.0)",

            width:"100%",
            height:"100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "right"
        },
          type: "percent",
          notation: "standard",
          grouping: false,
          decimals: 0
        }
    }
);

// red: 255,0,0
// green: 0,128,0. lime: 50,205,50
$.formatter?.cells?.formatters.push(
    {
        column: "Variance Percent",
        rowName: 'Metric',
        rowValue: 'Discounts: DTC Online',

        formatter : {
        style: {
            background: "rgba(0,128,0,1.0)",
            width:"100%",
            height:"100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "right"
        },
          type: "percent",
          notation: "standard",
          grouping: false,
          decimals: 0
        }
    }
);

$.formatter?.cells?.formatters.push(
    {
        column: "Variance Percent",
        rowName: 'Metric',
        rowValue: 'Returns: DTC Online',

        formatter : {
        style: {
            background: "rgba(0,128,0,0.5)",
            width:"100%",
            height:"100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "right"
        },
          type: "percent",
          notation: "standard",
          grouping: false,
          decimals: 0
        }
    }
);

   */

}

/* \
from PandL Table..
// let configMetadata =
// {
//   column: {
//     formatter: { // default format
//       width: 100,
//     },
//     formatters: [
//       {
//         column: 0,
//         width: 200,
//         formatter: {
//           type: "currency",
//           decimals: 2
//         }
//       },
//       {
//         column: "Metric",
//         width: 200,
//         formatter: {
//           type: "currency",
//           decimals: 2
//         }
//       }
//     ],
//   },
//   row: {
//     formatter: {},
//     formatters: [
//       {
//         column: "Metric",
//         row: "Gross Sales : DTC Online", // format the whole row located here.
//         formatter: {
//           type: "currency",
//           decimals: 2,
//           style: {
//             fontName: "Monaco",
//             fontSize: 15,
//             fontWeight: 400,
//             fontDecoration: ["italic"]
//             },
//         },
//       },
//       {
//         row: 5,
//         formatter: {
//           type: "currency",
//           decimals: 2,
//           style: {
//             fontName: "Monaco",
//             fontSize: 15,
//             fontWeight: 400,
//             fontDecoration: ["italic"]
//             },
//         },
//         hover: {}
//       }
//     ],
//   },
//   cell: {
//     formatters: [
//         {
//           column: "Metric", // can be number as index or other as value
//           row: "Gross Sales : DTC Online",
//           formatter: {
//             indent: 2, // assume this translates to ems or something
//             style: {
//               fontName: "Monaco",
//               fontSize: 15,
//               fontWeight: 400,
//               fontDecoration: ["italic"]
//               },
//               type: 'string'
//           }
//         }
//     ],
//   },
// }
 */
