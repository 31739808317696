/**
 * Copyright ©2023 Drivepoint
 */

import React from "react";
import DPAnimationLogo from "../../../assets/images/logos/drivepoint-animation.svg";

export type DPLoadingVeilProps = {
  show?: boolean
};

export default function DPPageLoader(props: DPLoadingVeilProps): any {

  return <div className="dp-loading-veil" style={{display: props.show ? "flex" : "none"}}>
    <img src={DPAnimationLogo} height={60} />
  </div>;

}
