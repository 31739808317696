/**
 * Copyright ©2022 Drivepoint
 */

import React from "react";
import {
  AccountTreeOutlined, AdminPanelSettingsOutlined, AirplaneTicketOutlined,
  AssessmentOutlined, AutoGraphOutlined, Dashboard, ElectricalServicesOutlined,
  Group, Info, InventoryOutlined, LayersOutlined, LineAxisOutlined, Lock,
  MonetizationOnOutlined, PeopleOutlined, PersonOutline, ScienceOutlined,
  Settings, SpeedOutlined, StackedBarChartOutlined, Star, Warning
} from "@mui/icons-material";

const AnalyticsInfoPage = React.lazy(() => import("../pages/info/AnalyticsInfoPage/AnalyticsInfoPage"));
const BundlePage = React.lazy(() => import("../pages/BundlePage/BundlePage"));
const ChartSandboxPage = React.lazy(() => import("../pages/ChartSandboxPage/ChartSandboxPage"));
const CompanySettingsPage = React.lazy(() => import("../pages/CompanySettingsPage/CompanySettingsPage"));
const CreateCompanyPage = React.lazy(() => import("../pages/administration/CreateCompanyPage/CreateCompanyPage"));
const CustomTokenPage = React.lazy(() => import("../pages/user/CustomTokenPage"));
const DashboardsInfoPage = React.lazy(() => import("../pages/info/DashboardsInfoPage/DashboardsInfoPage"));
const DataStudioPage = React.lazy(() => import("../pages/DataStudioPage"));
const DazzlerDashboardPage = React.lazy(() => import("@pages/dazzler/DazzlerDashboardPage/DazzlerDashboardPage"));
const DazzlerDashboardsPage = React.lazy(() => import("@pages/dazzler/DazzlerDashboardsPage/DazzlerDashboardsPage"));
const DazzlerStudioPage = React.lazy(() => import("@pages/dazzler/DazzlerStudioPage/DazzlerStudioPage"));
const DazzlerViewPage = React.lazy(() => import("@pages/dazzler/DazzlerViewPage/DazzlerViewPage"));
const ExternalLinkPage = React.lazy(() => import("../pages/ExternalLinkPage"));
const ExperimentsPage = React.lazy(() => import("../pages/ExperimentsPage/ExperimentsPage"));
const FinanceInfoPage = React.lazy(() => import("../pages/info/FinanceInfoPage"));
const FirebaseActionPage = React.lazy(() => import("../pages/user/FirebaseActionPage"));
const InfoPage = React.lazy(() => import("../pages/info/InfoPage"));
const IntuitDisconnectPage = React.lazy(() => import("../pages/intuit/IntuitDisconnectPage"));
const IntuitPage = React.lazy(() => import("../pages/intuit/IntuitPage"));
const InviteCompanyPage = React.lazy(() => import("../pages/administration/InviteCompanyPage"));
const InviteUserPage = React.lazy(() => import("../pages/user/InviteUserPage/InviteUserPage"));
const InviteUsersPage = React.lazy(() => import("../pages/administration/InviteUsersPage/InviteUsersPage"));
const LandingPage = React.lazy(() => import("../pages/LandingPage"));
const ManageCompaniesPage = React.lazy(() => import("../pages/administration/ManageCompaniesPage"));
const ManageUsersPage = React.lazy(() => import("../pages/administration/ManageUsersPage"));
const PlansPage = React.lazy(() => import("@pages/plans/PlansPage/PlansPage"));
const LegacyPlansPage = React.lazy(() => import("@pages/LegacyPlansPage/PlansPage/PlansPage"));
const PlansSwitchingPage = React.lazy(() => import("@pages/PlansSwitchingPage/PlansSwitchingPage"));
const NetsuiteSourceConfigurationPage = React.lazy(() => import("../pages/SourcesPage/Netsuite/NetsuiteSourceConfigurationPage"));
const PresencePage = React.lazy(() => import("../pages/administration/PresencePage/PresencePage"));
const QuickstartWelcomePage = React.lazy(() => import("../pages/quickstart/QuickstartWelcomePage/QuickstartWelcomePage"));
const ReportsPage = React.lazy(() => import("../pages/ReportsPage/ReportsPage"));
const ScorecardPage = React.lazy(() => import("../pages/ScorecardPage/ScorecardPage"));
const ScorecardsPage = React.lazy(() => import("../pages/ScorecardsPage/ScorecardsPage"));
const SettingsPage = React.lazy(() => import("../pages/SettingsPage/SettingsPage"));
const SignInPage = React.lazy(() => import("../pages/user/SignInPage/SignInPage"));
const SignUpPage = React.lazy(() => import("../pages/user/SignUpPage"));
const SourcesPage = React.lazy(() => import("../pages/SourcesPage/SourcesPage"));
const TeamPage = React.lazy(() => import("../pages/TeamPage/TeamPage"));
const UserDetailPage = React.lazy(() => import("../pages/TeamPage/UserDetailPage/UserDetailPage"));
const VersionsDashboardPage = React.lazy(() => import("../pages/administration/VersionsDashboardPage/VersionsDashboardPage"));

export default class ComponentRegistry {

  static classForName(name: string): any {
    switch (name) {
      case "AdminPanelSettingsOutlined": return AdminPanelSettingsOutlined;
      case "AirplaneTicketOutlined": return AirplaneTicketOutlined;
      case "BundlePage": return BundlePage;
      case "AnalyticsInfoPage": return AnalyticsInfoPage;
      case "AssessmentOutlined": return AssessmentOutlined;
      case "AutoGraphOutlined": return AutoGraphOutlined;
      case "ChartSandboxPage": return ChartSandboxPage;
      case "CompanySettingsPage": return CompanySettingsPage;
      case "CreateCompanyPage": return CreateCompanyPage;
      case "CustomTokenPage": return CustomTokenPage;
      case "DashboardsInfoPage": return DashboardsInfoPage;
      case "DataStudioPage": return DataStudioPage;
      case "Dashboard": return Dashboard;
      case "DazzlerDashboardPage": return DazzlerDashboardPage;
      case "DazzlerDashboardsPage": return DazzlerDashboardsPage;
      case "DazzlerStudioPage": return DazzlerStudioPage;
      case "DazzlerViewPage": return DazzlerViewPage;
      case "ElectricalServicesOutlined": return ElectricalServicesOutlined;
      case "ExternalLinkPage": return ExternalLinkPage;
      case "ExperimentsPage": return ExperimentsPage;
      case "FinanceInfoPage": return FinanceInfoPage;
      case "FirebaseActionPage": return FirebaseActionPage;
      case "Group": return Group;
      case "Info": return Info;
      case "InfoPage": return InfoPage;
      case "IntuitDisconnectPage": return IntuitDisconnectPage;
      case "IntuitPage": return IntuitPage;
      case "InventoryOutlined": return InventoryOutlined;
      case "InviteCompanyPage": return InviteCompanyPage;
      case "InviteUserPage": return InviteUserPage;
      case "InviteUsersPage": return InviteUsersPage;
      case "LandingPage": return LandingPage;
      case "LayersOutlined": return LayersOutlined;
      case "LineAxisOutlined": return LineAxisOutlined;
      case "Lock": return Lock;
      case "MonetizationOnOutlined": return MonetizationOnOutlined;
      case "AccountTreeOutlined": return AccountTreeOutlined;
      case "ManageCompaniesPage": return ManageCompaniesPage;
      case "ManageUsersPage": return ManageUsersPage;
      case "NetsuiteSourceConfigurationPage": return NetsuiteSourceConfigurationPage;
      case "PersonOutline": return PersonOutline;
      case "PresencePage": return PresencePage;
      case "QuickstartWelcomePage": return QuickstartWelcomePage;
      case "ReportsPage": return ReportsPage;
      case "PeopleOutlined": return PeopleOutlined;
      case "PlansPage": return PlansPage;
      case "PlansSwitchingPage": return PlansSwitchingPage;
      case "LegacyPlansPage": return LegacyPlansPage;
      case "ScorecardPage": return ScorecardPage;
      case "ScorecardsPage": return ScorecardsPage;
      case "Settings": return Settings;
      case "SettingsPage": return SettingsPage;
      case "SignInPage": return SignInPage;
      case "SignUpPage": return SignUpPage;
      case "SourcesPage": return SourcesPage;
      case "SpeedOutlined": return SpeedOutlined;
      case "StackedBarChartOutlined": return StackedBarChartOutlined;
      case "ScienceOutlined": return ScienceOutlined;
      case "Star": return Star;
      case "TeamPage": return TeamPage;
      case "UserDetailPage": return UserDetailPage;
      case "VersionsDashboardPage": return VersionsDashboardPage;
      case "Warning": return Warning;
    }
  }

}
