/**
 * Copyright ©2022 Drivepoint
 */

import React from "react";
import {Route, Routes} from "react-router-dom";
import usePathContext from "@hooks/usePathContext";
import useRoutes from "@hooks/useRoutes";
import useStateChange from "@hooks/useStateChange";
import {FirebaseAuthenticationState} from "@services/firebase/Firebase";
import ServiceRegistry from "@services/ServiceRegistry";
import DrivepointUser from "@services/user/DrivepointUser";
import DefaultPage from "../../pages/DefaultPage";
import LoadingPage from "../../pages/LoadingPage/LoadingPage";
import NotFoundPage from "../../pages/NotFoundPage";
import SignInPage from "../../pages/user/SignInPage/SignInPage";
import "./Routing.css";

export default function Routing(props: any): JSX.Element {

  const routes = useRoutes();
  const user = useStateChange<DrivepointUser>("user");
  const authentication = useStateChange<FirebaseAuthenticationState>("authentication");
  const pathContext = usePathContext();

  function renderPageRoute(page: any, index: number): any {
    if (page.path && page.component) {
      return <Route key={`route_${index}`} path={page.path} element={<page.component page={page} />} />;
    }
  }

  function renderPageRoutes(): any {
    return routes.pages?.map((page: any, index: number) => {
      if (authentication === FirebaseAuthenticationState.INITIALIZING) {
        return <Route key={`route_${index}`} path={page.path} element={<LoadingPage />} />;
      }
      if (page.public) { return renderPageRoute(page, index); }
      if (!user) { return <Route key={`route_${index}`} path={page.path} element={<SignInPage />} />; }
      if (!pathContext.companyId) { return renderPageRoute(page, index); }
      const company = user.companies.find((it: any) => it.id === pathContext.companyId);
      if (company) { return renderPageRoute(page, index); }
    });
  }

  function renderIndexPage(): any {
    if (authentication === FirebaseAuthenticationState.INITIALIZING) {
      return <Route key="route_index" index element={<LoadingPage />} />;
    }
    if (!user) {
      const page = ServiceRegistry.routeService.getPageById("sign_in");
      if (page) {
        return <Route key="route_index" index element={<page.component page={page} />} />;
      }
    }
    return <Route key="route_index" index element={<DefaultPage />} />;
  }

  function renderWildcardRoute(): any {
    // if services are still initializing, show loading page
    if (authentication === FirebaseAuthenticationState.INITIALIZING) {
      return <Route key="route_wildcard" path="*" element={<LoadingPage />} />;
    }
    // if user is authenticated, it's safe to say the given page isn't found
    if (authentication === FirebaseAuthenticationState.AUTHENTICATED) {
      return <Route key="route_wildcard" path="*" element={<NotFoundPage />} />;
    }
    // if user isn't authenticated, have them sign in
    return <Route key="route_wildcard" path="*" element={<SignInPage />} />;
  }

  function renderRoutes(): any {
    return <Routes>
      <>
        {renderIndexPage()}
        {renderPageRoutes()}
        {renderWildcardRoute()}
      </>
    </Routes>;
  }

  return <article className="routing">
    {renderRoutes()}
  </article>;

}
